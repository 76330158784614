.BlogSectionMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2% 2% 5% 2%;
}
.BlogCardImgDiv {
  /* border-radius: 15px; */
  background: #fff;
  overflow: hidden;
  height: 20vh;
  width: 100%;
}
.blogDate {
  color: #42c2ff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
}
.BlogCardImg {
  object-fit: cover;
  height: 100%;
  width: 100%;
  /* border-radius: 15px; */
  transform: scale(1.04);
  transition: 0.5s;
}
.BlogCardImg:hover {
  transform: scale(1.1);
}
.BlogCardMain {
  width: 100%;
}
.BlogCardMain h3 {
  color: #52525b;
  font-weight: 400;
  margin: 2% 0;
}
/* .BlogCardMain img {
  width: 100%;
} */
.BlogContainerGrid {
  display: flex;
  align-items: center;
  width: 80%;
}
.BlogGrid {
  margin: 2%;
}
.BlogCardTitle {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600 !important;
  color: #12141d;
  width: 90%;
}
@media only screen and (max-width: 1366px) {
  .BlogCardTitle {
    width: 100%;
    font-size: 16px;
  }
  .blogDate {
    font-size: 12px;
  }
}
@media only screen and (max-width: 900px) {
  .BlogCardTitle {
    width: 100%;
  }
}
/* @media only screen and (max-width: 600px) {
} */
@media only screen and (max-width: 480px) {
  .BlogCardImgDiv {
    height: 25vh;
  }
}
