.AboutSectionV2Container {
  padding: 5%;
  background: url(../../assets//images/aboutBG.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.AboutSectionV2ContentDiv {
  width: 45%;
  /* background-color: blue; */
}
.AboutSectionV2ContentDiv h3 {
  width: 60%;
  color: #0f52ba;
  font-family: Raleway;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 1.08px;
}
.AboutSectionV2ContentDiv p {
  width: 60%;
  color: #7a7a7a;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  margin-top: 3%;
}
.AboutSectionV2VideoDiv {
  width: 50%;
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 12px 46px 0px rgba(180, 199, 207, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.AboutSectionV2Thumbnail {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.AboutSectionV2PlayIcon {
  position: absolute;
  cursor: pointer;
  width: 80px;
  height: 80px;
}
.AboutSectionV2ModalContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.9) 100%);
  z-index: 1;
}
.AboutSectionV2ModalDiv {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.AboutSectionV2ModalClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.AboutSectionV2ModalDiv video {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1500px) {
  .AboutSectionV2Container {
    padding: 8% 5%;
  }
  .AboutSectionV2ContentDiv h3 {
    width: 70%;
  }
  .AboutSectionV2ContentDiv p {
    width: 70%;
  }
  .AboutSectionV2PlayIcon {
    width: 60px;
    height: 60px;
  }
}
@media only screen and (max-width: 1366px) {
  .AboutSectionV2ContentDiv h3 {
    width: 80%;
    font-size: 32px;
  }
  .AboutSectionV2ContentDiv p {
    width: 80%;
    font-size: 18px;
  }
}
@media only screen and (max-width: 1200px) {
  .AboutSectionV2ContentDiv h3 {
    width: 80%;
  }
  .AboutSectionV2ContentDiv p {
    width: 80%;
  }
  .AboutSectionV2PlayIcon {
    width: 50px;
    height: 50px;
  }
}
@media only screen and (max-width: 1024px) {
  .AboutSectionV2ContentDiv h3 {
    width: 90%;
    font-size: 28px;
  }
  .AboutSectionV2ContentDiv p {
    width: 90%;
    font-size: 16px;
  }
  .AboutSectionV2PlayIcon {
    width: 50px;
    height: 50px;
  }
  .AboutSectionV2VideoDiv {
    border-radius: 30px;
  }
}
@media only screen and (max-width: 900px) {
  .AboutSectionV2ContentDiv h3 {
    width: 100%;
    font-size: 24px;
  }
  .AboutSectionV2ContentDiv p {
    width: 100%;
    font-size: 14px;
  }
  .AboutSectionV2PlayIcon {
    width: 40px;
    height: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .AboutSectionV2Container {
    flex-direction: column;
  }
  .AboutSectionV2ContentDiv {
    width: 100%;
    text-align: center;
    margin: 2% 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .AboutSectionV2ContentDiv h3 {
    width: 80%;
  }
  .AboutSectionV2ContentDiv p {
    width: 80%;
  }
  .AboutSectionV2VideoDiv {
    width: 80%;
    margin-top: 5%;
  }
  .AboutSectionV2PlayIcon {
    width: 50px;
    height: 50px;
  }
}
@media only screen and (max-width: 600px) {
  .AboutSectionV2ContentDiv h3 {
    width: 100%;
    font-size: 20px;
  }
  .AboutSectionV2ContentDiv p {
    width: 100%;
    font-size: 12px;
  }
  .AboutSectionV2VideoDiv {
    width: 100%;
  }
  .AboutSectionV2ModalDiv {
    width: 90%;
  }
}
@media only screen and (max-width: 480px) {
  .AboutSectionV2PlayIcon {
    width: 40px;
    height: 40px;
  }
  .AboutSectionV2ModalDiv {
    width: 90%;
  }
}
