.DetailLogo_Container{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5% 0;
}
.DetailLogo_Div{
    width: 40%;
}
.DetailLogo_Img{
    width:100%;
}
@media only screen and (max-width: 600px) {
    .DetailLogo_Div{
        width: 80%;
    }

}