.errorSectionContainer {
  padding: 5%;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.errorSectionContentDiv {
  width: 50%;
  font-family: 'Raleway', sans-serif;
}
.errorSectionContentDiv h1 {
  font-size: 54px;
  font-weight: 700;
  color: #003b7e;
}
.errorSectionContentDiv h2 {
  font-size: 36px;
  font-weight: 700;
  color: #282828;
  margin: 2% 0;
  width: 85%;
}
.errorDivider {
  height: 5px;
  border-radius: 4px;
  width: 20%;
  background: #f8f8f8;
}
.errorSectionContentDiv p {
  font-size: 22px;
  font-weight: 400;
  color: #00000060;
  margin: 3% 0;
  width: 85%;
}
.errorSectionBtnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2%;
  margin-top: 2%;
}
.ErrorSectionNavLinks {
  padding: 2% 4%;
  background: #f8f8f8;
  border-radius: 10px;
  color: #003b7e;
  font-weight: 700;
  font-size: 16px;
  transition: 0.5s;
  white-space: nowrap;
}
.ErrorSectionNavLinksActive {
  padding: 2% 5%;
  background: #003b7e;
  border-radius: 10px;
  color: #f8f8f8;
  font-weight: 700;
  font-size: 16px;
  transition: 0.5s;
}
.ErrorSectionNavLinks:hover {
  background: #003b7e;
  color: #f8f8f8;
}
.ErrorSectionNavLinksActive:hover {
  background: #0156b7;
  color: #f8f8f8;
}
.errorSectionImageDiv {
  width: 50%;
}
.errorSectionImageDiv img {
  width: 100%;
}
@media only screen and (max-width: 1500px) {
  .errorSectionContentDiv h1 {
    font-size: 48px;
  }
  .errorSectionContentDiv h2 {
    font-size: 32px;
    width: 90%;
  }
  .errorSectionContentDiv p {
    font-size: 18px;
    width: 90%;
  }
}
@media only screen and (max-width: 1200px) {
  .errorSectionContentDiv h1 {
    font-size: 42px;
  }
  .errorSectionContentDiv h2 {
    font-size: 28px;
    width: 95%;
  }
  .errorSectionContentDiv p {
    font-size: 16px;
    width: 95%;
  }
}
@media only screen and (max-width: 1024px) {
  .errorSectionContentDiv h1 {
    font-size: 38px;
  }
  .errorSectionContentDiv h2 {
    font-size: 24px;
    width: 100%;
  }
  .errorSectionContentDiv p {
    font-size: 16px;
    width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .errorSectionContainer {
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 5% 5% 0 5%;
  }
  .errorSectionContentDiv {
    margin-top: 10%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .errorSectionContentDiv h1 {
    font-size: 38px;
  }
  .errorSectionContentDiv h2 {
    text-align: center;
  }

  .errorSectionContentDiv p {
    text-align: center;
  }
  .errorSectionBtnContainer {
    justify-content: center;
  }
  .errorSectionImageDiv {
    width: 70%;
  }
}
@media only screen and (max-width: 768px) {
  .errorSectionContentDiv {
    width: 90%;
  }
  .errorSectionImageDiv {
    width: 80%;
  }
}
@media only screen and (max-width: 600px) {
  .errorSectionContentDiv {
    margin-top: 15%;
    width: 100%;
  }
  .errorSectionContentDiv h1 {
    font-size: 32px;
  }
  .errorSectionContentDiv h2 {
    font-size: 20px;
  }
  .errorSectionContentDiv p {
    font-size: 16px;
  }
  .errorSectionImageDiv {
    width: 100%;
  }
  .ErrorSectionNavLinks {
    font-size: 14px;
    padding: 3%;
  }
  .ErrorSectionNavLinksActive {
    padding: 3%;
    font-size: 14px;
  }
}
@media only screen and (max-width: 480px) {
  .errorSectionContentDiv {
    margin-top: 20%;
  }
  .errorDivider {
    width: 30%;
  }
  .errorSectionBtnContainer {
    gap: 2%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .ErrorSectionNavLinks {
    width: 31%;
    white-space: nowrap;
    text-align: center;
    margin: 2% 0;
  }
  .ErrorSectionNavLinksActive {
    width: 100%;
    white-space: nowrap;
    text-align: center;
    margin: 2% 0;
  }
}
