.ProjectCardMain{
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 90%; */
    margin:3% 0;
}
.projectCardMob{
    display: none;
}
.ProjectCardContainer1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95% !important;
}
.ProjectCardContainer2{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95% !important;

}
.ProjectCardTypo{
    margin-left:15% !important;
    width: 80%;
    /* text-align: center; */

}
.ProjectCardTitle{
        color: #000 !important;
        font-size: 2.2rem !important;
        font-weight: 800 !important;
}
.ProjectCardDesp{
        font-size: 1.2rem !important;
        font-weight: 400 !important;
        width: 95% !important;
        margin: 2% 0% !important;
}
.ProjectCardBtn{
    border: 3px solid #003B7E !important;
    border-radius: 10px !important;
    color:#003B7E !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    margin-top: 5% !important;
    text-transform: capitalize !important;
    width: 35%;
}
.ProjectCardImg{
    border-radius: 20px;
}
@media only screen and (max-width: 1024px) {
.ProjectCardTitle{
    
    font-size: 1.5rem !important;
}
.ProjectCardDesp{
    font-size: 1rem !important;
    width: 100% !important;
}
}
@media only screen and (max-width: 900px) {
    .ProjectCardContainer1{
        flex-direction: column-reverse !important;
    }
    .ProjectCardTypo{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        margin: 2% 0% !important;

    }
    .ProjectCardMain{
        margin:10% 0;
    }
    .ProjectCardBtn{
        margin-top: 2% !important;
        width: 100%;
    }

    
    /* .ProjectCardDivider{
        display: flex !important;
        align-items: center !important;
    } */


}
@media only screen and (max-width: 600px) {
    .ProjectCardBtn{
        width: 45%;
        margin-top: 1% !important;
    }
    .ProjectCardMain{
        margin:15% 0;
    }
    .ProjectCardDesp{
        font-size: 1rem !important;
    }
    .projectCardWeb{
        display: none;
    }
    .projectCardMob{
        display: block;
    }
    .ProjectCardImg{
        width: 350%;
    }
}
/*  
@media only screen and (max-width: 600px) {
    .ProjectCardBtn{
        width: 40%;
    }
}

*/