.profileCardContainer {
  width: 100%;
  border-radius: 10px;
  background: #fff;
  padding: 3%;
  overflow: hidden;
}
.profileCardUpperDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.profileCardImgDiv {
  width: 20%;
}
.profileCardImgDiv img {
  width: 100%;
  border-radius: 120px;
  background: #e1f5ff;
}
.profileCardInfoDiv {
  width: 75%;
}
.profileCardInfoDiv h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  background: linear-gradient(90deg, #153e4a 0%, #0d1d2e 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.profileCardInfoDiv h4 {
  color: #7a7a7a;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  margin: 1% 0;
}
.profileCardDesignationDiv {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5%;
}
.profileCardDesignationDiv p {
  color: #919b9b;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
}
.profileCardAchievementDiv {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2%;
  margin-top: 2%;
}
.profileCardAchievementTags {
  display: flex;
  padding: 1% 3%;
  margin-top: 2%;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #e1f5ff;
}
.profileCardAchievementTags p {
  color: #292929;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
}
/* Card V2 */
.profileCardV2Container {
  width: 100%;
  border-radius: 20px;
  display: flex;
  padding: 5% 5% 1% 5%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 100px; */
  align-self: stretch;
  user-select: none;
}
.profileCardV2InfoDiv {
  width: 100%;
}
.profileCardV2InfoDiv h3 {
  width: 100%;
  color: #fff;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 700;
}
.profileCardV2DesignationDiv {
  width: 100%;
  display: flex;
  gap: 5%;
  margin: 1% 0;
}
.profileCardV2DesignationDiv p {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}
.profileCardV2ImageDiv {
  width: 100%;
  display: flex;
  padding: 5%;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  margin: 5% 0;
}
.profileCardV2ImageDiv img {
  width: 100%;
  border-radius: 20px;
  background: lightgray -121.199px 11.32px / 177.381% 231.334% no-repeat, rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}
.profileCardV2DespDiv {
  width: 100%;
  margin: 1% 0;
  display: flex;
  font-family: Poppins;
  flex-wrap: wrap;
  /* height: 8vh; */
}
.profileCardV2DespDiv::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and newer versions of Edge */
}
.profileCardV2DespDiv h2 {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.profileCardV2AchievementDiv {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5%;
  margin: 3% 0;
}
.profileCardV2AchievementTags {
  display: flex;
  padding: 2% 5%;
  justify-content: center;
  white-space: nowrap;
  align-items: center;
  border-radius: 100px;
  margin: 1% 0;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
}
.profileCardV2AchievementTags p {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 1500px) {
  .profileCardInfoDiv h3 {
    font-size: 22px;
  }
  .profileCardInfoDiv h4 {
    font-size: 18px;
  }
  .profileCardDesignationDiv p {
    font-size: 18px;
  }
  .profileCardAchievementTags p {
    font-size: 16px;
  }
  /*  Profile Card V2 */
  .profileCardV2DespDiv h2 {
    font-size: 20px;
  }
  .profileCardV2AchievementTags p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1200px) {
  .profileCardInfoDiv h3 {
    font-size: 20px;
  }
  .profileCardInfoDiv h4 {
    font-size: 16px;
  }
  .profileCardDesignationDiv p {
    font-size: 16px;
  }
  .profileCardAchievementTags p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1024px) {
  .profileCardInfoDiv h3 {
    font-size: 20px;
  }
  .profileCardInfoDiv h4 {
    font-size: 16px;
  }
  .profileCardDesignationDiv p {
    font-size: 16px;
  }
  .profileCardAchievementTags p {
    font-size: 14px;
  }
  /*  Profile Card V2 */
  .profileCardV2AchievementDiv {
    gap: 3%;
  }
  .profileCardV2AchievementTags {
    padding: 2% 3%;
  }
  .profileCardV2DespDiv h2 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 900px) {
  .profileCardInfoDiv h3 {
    font-size: 18px;
  }
  .profileCardInfoDiv h4 {
    font-size: 16px;
  }
  .profileCardDesignationDiv p {
    font-size: 16px;
  }
  .profileCardAchievementTags p {
    font-size: 14px;
    white-space: nowrap;
  }
  .profileCardV2AchievementDiv {
    gap: 5%;
  }
  .profileCardV2AchievementTags {
    padding: 2% 5%;
  }
}
@media only screen and (max-width: 768px) {
  .profileCardV2AchievementTags {
    padding: 2% 3%;
  }
  .profileCardV2AchievementTags p {
    font-size: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .profileCardV2AchievementDiv {
    gap: 5%;
  }
  .profileCardV2AchievementTags {
    padding: 2% 5%;
  }
  .profileCardV2AchievementTags p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 480px) {
  .profileCardInfoDiv h3 {
    font-size: 16px;
  }
  .profileCardDesignationDiv p {
    font-size: 14px;
  }
  .profileCardV2DespDiv h2 {
    font-size: 16px;
  }
  .profileCardV2AchievementDiv {
    gap: 3%;
  }
  .profileCardV2AchievementTags {
    padding: 2% 4%;
  }
  .profileCardV2AchievementTags p {
    font-size: 12px;
  }
}
