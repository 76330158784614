.VisionDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2%;
}
.VisionTypo{
    width: 45%;
    padding: 2%;
}
.VisionImgDiv{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.VisionImg{
    width: 65%;
}

.VisionList li{
    padding: .5% 0%;
    font-weight: 400;
    font-size: 1rem;
}
@media only screen and (max-width: 1366px) {
    .VisionImg{
        width: 75%;
    }
}
@media only screen and (max-width: 1024px) {
    .VisionImg{
        width: 90%;
    }
}
@media only screen and (max-width: 900px) {
  
    .VisionDiv{
        flex-direction: column-reverse;


    }
    .VisionImgDiv{
        width: 100%;
    }
    .VisionImg{
        width: 100%;

    }
    .VisionTypo{
        display: flex;
        flex-direction: column;
        /* align-items: center;
        text-align: center;
        justify-content: center; */
        width: 80%;
        padding: 2%;
    }
  }
@media only screen and (max-width: 600px) {
  
    
    .VisionTypo{
        display: flex;
        flex-direction: column;
        /* align-items: center;
        text-align: center;
        justify-content: center; */
        width: 80%;
        padding: 2%;
    }
    

  }