.DetailMoreProjects_SectionHeaderDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.DetailMoreProjects_SectionHeader_H1 {
  margin: 1% 0;
  color: #42c2ff;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
}
.DetailMoreProjects_SectionHeader_H2 {
  margin: 1% 0;
  color: #003b7e;
}

.DetailMoreProjects_SectionHeader_Divider {
  height: 5px;
  width: 10%;
  background: #42c2ff;
  border-radius: 10px;
}
.DetailMoreProjects_MainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5% 7%;
}
.MoreProjectCards_MainDiv {
  width: 100%;
  object-fit: cover;
  height: 250px;
  position: relative;
  border-radius: 10px;
  transform: scale(1);
  transition: 0.5s;
  overflow: hidden;
  z-index: 1;
}
.MoreProjectCards_MainDiv:hover {
  transform: scale(1.1);
}
.MoreProjectCards_HoverDiv {
  position: absolute;
  border-radius: 10px;
  opacity: 0;
  transition: 1s;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(157, 60, 100, 0.014) 0%, rgba(0, 0, 0, 0.9) 95.27%);
}
.MoreProjectCards_TextDiv {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-end;
  padding: 2% 5%;
  border-radius: 10px;
}
.MoreProjectCards_Title {
  color: #fff;
  font-size: 2rem;
}
.MoreProjectCards_Description {
  text-align: justify;
  color: #fff;
  font-size: 1rem;
  padding: 2% 0;
}
.MoreProjectCards_Image {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.MoreProjectCards_HoverDiv:hover {
  opacity: 1;
}
/* .MoreProjectCards_MainDiv:hover .MoreProjectCards_HoverDiv {
  opacity: 1;
} */
.DetailMoreProjects_ContainerGrid {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 1366px) {
  .MoreProjectCards_MainDiv {
    height: 200px;
  }
}
@media only screen and (max-width: 1024px) {
  .MoreProjectCards_MainDiv {
    height: 150px;
  }
  .MoreProjectCards_Description {
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 900px) {
  .DetailMoreProjects_ContainerGrid {
    justify-content: center;
  }
  .DetailMoreProjects_Grid {
    margin: 3% 0;
  }
  .MoreProjectCards_MainDiv {
    height: 250px;
  }
}
@media only screen and (max-width: 600px) {
  .MoreProjectCards_MainDiv:hover {
    transform: scale(1.03);
  }
  .MoreProjectCards_HoverDiv {
    opacity: 1;
  }
}
