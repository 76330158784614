.certificationContainer {
  width: 100%;
  padding: 5% 0;
}
.certificationHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 5%;
}
.certificationHeader h2 {
  width: 100%;
  color: #0f52ba;
  font-family: Raleway;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 1.08px;
}
.certificationHeader p {
  width: 70%;
  color: #7a7a7a;
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  margin: 1% 0;
}

/* .certificationSlider img {
  width: 200px;
  height: auto;
} */
.animate {
  animation-name: carousel;
}
/* .marquee {
  width: 100%;
  display: flex;
  gap: 10%;
} */
.certificationSlider {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 5rem;
}
.certificates {
  width: 200px;
  height: auto;
  margin: 0 40px;
}

/* sadsa */

.inner {
  position: relative;
  width: 100%;
  margin-top: 2%;
  /* overflow: hidden; */
  height: fit-content;
}

.wrapper {
  display: flex;
  align-items: center;
  height: fit-content;
}

.secimg {
  height: fit-content;
  animation: swipe var(--speed) linear infinite backwards;
}

.certifcateimage {
  height: fit-content;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.certifcateimage img {
  width: 160px;
}

.certifcateimage img:last-of-type {
  padding-left: 0;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}
/* sadasd */

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 80px));
  }
}
@keyframes carousel {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
/* .certificationSlider {
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  animation: marquee 30s linear infinite;
}
.certificates {
  width: 200px;
  height: auto;
  margin: 0 40px;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 80px));
  }
} */
@media only screen and (max-width: 900px) {
  .certificationHeader h2 {
    font-size: 32px;
  }
  .certificationHeader p {
    font-size: 18px;
    width: 80%;
  }
  .certificates {
    width: 150px;
  }
}
@media only screen and (max-width: 768px) {
  .certificationHeader h2 {
    font-size: 28px;
  }
  .certificationHeader p {
    font-size: 16px;
    width: 95%;
  }
  .certificates {
    width: 130px;
    margin: 0 30px;
  }
}
@media only screen and (max-width: 600px) {
  .certificationHeader h2 {
    font-size: 24px;
  }
  .certificates {
    width: 120px;
    margin: 0 20px;
  }
  .certifcateimage {
    width: 120px;
  }

  .certifcateimage img {
    width: 100px;
  }
}
