.availableProfilesContainer {
  padding: 5%;
  background: #f8f8f8;
  width: 100%;
}
.availableProfilesSection {
  width: 100%;
  margin: 2% 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.availableProfilesDiv {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2% 0;
}

/* Card 2 */
.availableProfilesSectionV2 {
  width: 100% !important;
  background: #f8f8f8 !important;
  padding: 0 0 2% 5% !important;
  display: flex !important;
  align-items: center !important;
  /* justify-content: space-evenly; */
  overflow-x: scroll !important;
  white-space: nowrap !important;
}
.availableProfilesSectionV2 ul {
  display: flex !important;
  list-style: none !important;
}
.availableProfilesSectionV2::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and newer versions of Edge */
}
.availableProfilesDivV2 {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2%;
}
@media only screen and (max-width: 1500px) {
  .availableProfilesDiv {
    width: 45%;
  }
}
@media only screen and (max-width: 1024px) {
  .availableProfilesSection {
    justify-content: space-evenly;
  }

  .availableProfilesDiv {
    width: 48%;
  }
}
@media only screen and (max-width: 900px) {
  .availableProfilesSection {
    justify-content: center;
    flex-direction: column;
  }
  .availableProfilesDiv {
    width: 60%;
  }
}
@media only screen and (max-width: 768px) {
  .availableProfilesDiv {
    width: 70%;
  }
}
@media only screen and (max-width: 600px) {
  .availableProfilesDiv {
    width: 90%;
  }
  .availableProfilesSectionV2 {
    padding: 0 5% 2% 5%;
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .availableProfilesDiv {
    width: 100%;
  }
}
