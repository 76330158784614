.AboutCompanySection{
    display: flex;
    align-items: center;
    padding: 5%;
    background-color: #F8F8F8;
}
.AboutCompanyTypo{
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    width: 85%;
}
.AboutCompanyTypo_H1{
        font-size: 1.2rem !important;
        color: #42C2FF;
        font-weight: 600;
        text-transform: uppercase;
}
.AboutCompanyTypo_H2{
        font-size: 1.7rem !important;
        margin: 2% 0% !important;
        width: 100%;
        color: #0F52BA;
        font-weight: 800 !important;
        text-transform: capitalize;
}
.AboutCompanyTypo_Desp{
    font-size: 1rem !important; 
    width: 90%;
    font-weight: 400 !important;
    color: #003B7E;
}
.AboutCompanyImg{
    width: 85%;
}
@media only screen and (max-width: 1366px) {

.AboutCompanyTypo_Desp{
    font-size: 1rem !important; 
}
.AboutCompanyImg{
    width: 95%;
}
}
@media only screen and (max-width: 1024px) {
    .AboutCompanyTypo_H1{
        font-size: 1rem !important;
    }
    .AboutCompanyTypo_H2{
        font-size: 1.5rem !important;
}
    .AboutCompanyTypo_Desp{
        font-size: .8rem !important;
    }
}
@media only screen and (max-width: 900px) {
    .AboutCompanyTypo_H1{
        font-size: 1.2rem !important;
    }
    .AboutCompanyTypo_H2{
        font-size: 1.7rem !important;
}
    .AboutCompanyTypo_Desp{
        font-size: 1rem !important;
        width: 85%;
    }
    .AboutCompanyImg{
        width: 100%;
    }
    .AboutCompanySection{
        justify-content: center;
    }
    .AboutCompanyTypo{
        justify-content: center ;
        text-align: center ;
        align-items: center ;
        width: 100% !important;
    }
    .AboutCompanyImgDiv{
        display: flex;
        align-items: center;
        justify-content: center;
    width: 100% !important;
}
}

@media only screen and (max-width: 600px) {
   
    .AboutCompanyTypo_H2{
        width: 100%;
        padding: 0 2%;
        font-size: 1.5rem !important;

}
    .AboutCompanyTypo_Desp{
        width: 90%;
    }
    
  }