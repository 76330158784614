.detail-book-demo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
  background-color: #f0f0f0;
}

.detail-book-demo-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
}
.wrapperClassDemo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 3% 0;
}

.detail-book-demo-heading {
  font-size: 48px;
  font-family: 'Raleway';
  font-weight: 800;
  margin: 1% 0;
  color: '#1D8DE2';
}

.detail-book-demo-paragraph {
  font-family: 'Raleway';
  font-size: 18px;
  font-weight: 400;
  width: 70%;
}

.detail-book-demo-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
}

.detail-book-demo-button {
  color: white;
  width: 50%;
  padding: 4% 0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Raleway';
  font-weight: 600;
}

.detail-book-demo-image-container {
  position: relative;
  width: 100%;
  height: 50vh;
  margin-top: 2%;
}

.detail-book-demo-image {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media only screen and (max-width: 1366px) {
  .detail-book-demo-paragraph {
    width: 90%;
    font-size: 16px;
  }
  .detail-book-demo-button {
    width: 70%;
  }
  .detail-book-demo-heading {
    font-size: 38px;
  }
}
@media only screen and (max-width: 1024px) {
  .detail-book-demo-paragraph {
    width: 100%;
  }
  .detail-book-demo-heading {
    font-size: 32px;
  }
  .detail-book-demo-button {
    width: 90%;
    padding: 5% 0;
  }
}
@media only screen and (max-width: 900px) {
  .wrapperClassDemo {
    justify-content: center;
    flex-direction: column;
  }
  .detail-book-demo-content {
    align-items: center;
    width: 100%;
  }
  .detail-book-demo-heading {
    font-size: 24px;
    text-align: center;
  }
  .detail-book-demo-paragraph {
    text-align: center;
  }
  .detail-book-demo-button-container {
    justify-content: center;
    width: 40%;
  }
  .detail-book-demo-button {
    width: 100%;
    padding: 5% 0;
    margin-top: 5%;
  }
}
@media only screen and (max-width: 600px) {
  .detail-book-demo-button-container {
    justify-content: center;
    width: 60%;
  }
  .detail-book-demo-image-container {
    height: 40vh;
  }
}
@media only screen and (max-width: 480px) {
  .detail-book-demo-image-container {
    height: 35vh;
    margin-top: 5%;
  }
}
@media only screen and (max-width: 380px) {
  .detail-book-demo-image-container {
    height: 30vh;
  }
}
