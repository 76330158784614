.ServiceProvided_Card {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #003B7E;
    border-radius: 20px;
    object-fit: cover;
    overflow: hidden;
    height: 320px;

}

.ServiceProvided_CardTypo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55%;
}

.ServiceProvided_SubCardTypo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70%;
}

.ServiceProvided_CardTitle {
    font-size: 2rem !important;
    color: #003B7E;
    font-weight: 700 !important;
    /* padding: 10% 0% 10% 10% !important; */
    /* height: 60%; */
}

.ServiceProvided_CardBtn {
    border: none;
    background-color: #F8F8F8;
    /* padding: 10% 0% 10% 10%; */
    color: #003B7E;
    font-weight: 600;
    font-size: 1.1rem;
    cursor: pointer;
}

.ServiceProvided_CardImgDiv {
    width: 45%;
    overflow: visible;
}

.ServiceProvided_CardImg {
    height: 100%;
    width: 100%;
    overflow: visible;
    object-fit: cover;
}

/* Verticle Card */
.ServiceProvided_VerticleCard {


    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #003B7E;
    border-radius: 20px;
    object-fit: cover;
    overflow: hidden;
    height: 670px;

}

.ServiceProvided_VerticleCardTypo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 350px;
}

.ServiceProvided_VerticleSubCardTypo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.ServiceProvided_VerticleCardImgDiv {
    width: 100%;
    overflow: visible;
}

.ServiceProvided_VerticleCardImg {
    height: 100%;
    width: 100%;
    margin-bottom: -6px;
    object-fit: cover;
}


.ServiceProvidedDiv {
    padding: 2%;
    /* height: 100vh; */
    background-color: #F8F8F8;

}

.ServiceProvidedMain {
    display: flex;
    justify-content: center;


}

.CardGridMain {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 95% !important;


}

.ServiceProvidedBoxes {
    display: flex;
    justify-content: center;
}
.CardGridMain_MobView{
    display: none;
}

/* .CardGridLeftMain{
}
.CardGridLeft{
}
.VerticleCard{

} */
/* .ServiceProvidedTypo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 2% 0% 3% 0%;
}
.ServiceProvidedH1{
    text-transform: uppercase;
    color: #42C2FF;
    font-weight: 600;
}
.ServiceProvidedH2{
    font-size: 1.3rem !important;
    text-transform: capitalize;
    color: #003B7E;
    font-weight: 800 !important;
}
.ServiceProvidedDesp{
    font-size: .8rem !important;
    color: #404040B2 !important;
    font-weight: 400 !important;
} */
@media only screen and (max-width: 1680px) {
    .ServiceProvided_Card {
        height: 250px;

    }

    .ServiceProvided_VerticleCard {
        height: 520px;

    }

    .ServiceProvided_VerticleCardTypo {
        height: 200px;
    }

    .ServiceProvided_CardTitle {
        font-size: 1.7rem !important;
    }
}

@media only screen and (max-width: 1366px) {
    .ServiceProvided_CardTitle {
        font-size: 1.5rem !important;
    }

    .ServiceProvided_Card {
        height: 220px;
    }

    .ServiceProvided_VerticleCard {
        height: 460px;
    }

    .ServiceProvided_CardBtn {
        font-weight: 500;
        font-size: 1rem;
    }
}

@media only screen and (max-width: 1024px) {
    .ServiceProvided_Card {
        height: 180px;
    }

    .ServiceProvided_VerticleCard {
        height: 380px;
    }

    .ServiceProvided_VerticleCardTypo {
        height: 200px;
    }

    .ServiceProvided_CardTitle {
        font-size: 1.3rem !important;
    }

    .ServiceProvided_CardTitle {
        font-size: 1.2rem !important;
    }


}

@media only screen and (max-width: 900px) {
    .ServiceProvided_Card {
        height: 180px;
    }

    .VerticleCard_ContainerGrid {
        display:  none !important;
    }

    .ServiceProvided_VerticleCard {
        max-height: 500px;
        min-height: 500px;
        width: 100%;

    }

    .ServiceProvided_VerticleCardTypo {
        height: 200px;
    }

    .ServiceProvided_CardTitle {
        font-size: 1.2rem !important;
    }

    .ServiceProvided_CardBtn {
        font-size: 1rem;
    }
    .CardGridMain_MobViewDiv{
        width:100%;
        display: flex;
        justify-content: center;
    }
    .CardGridMain_MobView{
        display: block;

    }
}

@media only screen and (max-width: 768px) {
    .ServiceProvided_CardTitle {
        font-size: 1rem !important;
    }

    .ServiceProvided_Card {
        height: 150px;
    }
}

@media only screen and (max-width: 600px) {
    .ServiceProvided_Card {
        height: 250px;
    }

    .ServiceProvided_VerticleCard {
        max-height: 700px;
        min-height: 700px;
        width: 100%;

    }

    .ServiceProvided_CardTitle {
        font-size: 1.7rem !important;
    }

    .ServiceProvided_CardBtn {
        font-size: 1rem;
    }
}
@media only screen and (max-width: 400px) {
    .ServiceProvided_Card {
        height: 180px;
    }
    .ServiceProvided_CardTitle {
        font-size: 1.3rem !important;
    }
}