.modernAgencyV2Container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5% 0;
}

.modernAgencyV2Header {
  width: 100%;
}
.modernAgencyV2Header h2 {
  width: 100%;
  color: #0f52ba;
  font-family: Raleway;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 1.08px;
  text-align: center;
}
.modernAgencyV2Header p {
  width: 100%;
  color: #7a7a7a;
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin: 1% 0;
}
.modernAgencyV2BoxDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1% 5%;
  margin-top: 2%;
}
.modernAgencyV2Box {
  width: 23%;
}
.modernAgencyV2Box:hover {
  border-radius: 10px;
  background: #f3f8fa;
}

/* modernAgencyV2Box CSS */
.modernAgencyV2BoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15% 0;
}
.modernAgencyV2BoxContainer img {
  width: 35%;
}
.modernAgencyV2BoxContainer h3 {
  color: #003b7e;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  margin: 7% 0 3% 0;
}
.modernAgencyV2BoxContainer p {
  color: #003b7e;
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 500;
}
@media only screen and (max-width: 1500px) {
  .modernAgencyV2Header h2 {
    font-size: 28px;
  }
  .modernAgencyV2Header p {
    font-size: 16px;
  }
  .modernAgencyV2BoxContainer img {
    width: 30%;
  }
  .modernAgencyV2BoxContainer h3 {
    font-size: 28px;
  }
  .modernAgencyV2BoxContainer p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1200px) {
  .modernAgencyV2BoxContainer img {
    width: 25%;
  }
  .modernAgencyV2BoxContainer h3 {
    font-size: 24px;
  }
  .modernAgencyV2BoxContainer p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 900px) {
  .modernAgencyV2BoxContainer img {
    width: 35%;
  }
  .modernAgencyV2BoxContainer h3 {
    font-size: 20px;
  }
  .modernAgencyV2BoxContainer p {
    font-size: 12px;
  }
}
@media only screen and (max-width: 768px) {
  .modernAgencyV2Header p {
    font-size: 14px;
  }
  .modernAgencyV2BoxDiv {
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .modernAgencyV2Box {
    width: 40%;
    margin: 1% 0;
  }
}
@media only screen and (max-width: 480px) {
  .modernAgencyV2Container {
    padding: 10% 0;
  }
  .modernAgencyV2Header h2 {
    font-size: 24px;
  }
  .modernAgencyV2BoxDiv {
    justify-content: center;
    margin-top: 5%;
  }

  .modernAgencyV2Box {
    width: 90%;
    margin: 2% 0;
  }
  .modernAgencyV2BoxContainer {
    padding: 5% 0;
  }
  .modernAgencyV2BoxContainer img {
    width: 25%;
  }
  .modernAgencyV2BoxContainer h3 {
    margin: 4% 0 2% 0;
  }
}
