.BlogListMain{
    margin: 2%;
}
.BlogListContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.BlogListGrid{
    margin: 4%;
}
.BlogBtnGrid{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20vh !important;
}
.BlogBtn{
    border: 3px solid #0F52BA !important;
    border-radius: 10px !important;
    color: #0F52BA !important;
    width: 11%;
    font-size: 1.1rem !important;
    font-weight: 700 !important;
    text-transform: capitalize !important;
    padding: .8% 0% !important;

}
@media only screen and (max-width: 900px) {
    .BlogBtn{
        width: 18%;
    
    }
  }
@media only screen and (max-width: 600px) {
    .BlogBtn{
        width: 35%;
        font-size: 1rem !important;
    
    }
  }