.reviewCardV2Container {
  width: 100%;
  border-radius: 10px;
  background: #fff;
  padding: 5%;
  min-height: 250px;
  max-height: 100%;
  overflow: hidden;
}
.reviewCardV2Container h3 {
  color: #454238;
  font-family: Poppins;
  font-size: 21px;
  font-weight: 500;
}
.reviewCardV2Container h4 {
  color: #919b9b;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  margin: 1% 0;
}
.reviewCardV2Container p {
  overflow: hidden;
  color: #454238;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-top: 3%;
}
@media only screen and (max-width: 1366px) {
  .reviewCardV2Container {
    min-height: 200px;
  }
  .reviewCardV2Container h3 {
    font-size: 18px;
  }
  .reviewCardV2Container h4 {
    font-size: 14px;
  }
  .reviewCardV2Container p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1024px) {
  .reviewCardV2Container p {
    font-size: 14px;
  }
}
