.ProjectCard_Container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
  position: relative;
}
.ProjectCard_BgImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  opacity: 0.05;
}
.ProjectCard_MainDiv {
  width: 70%;
  height: 50vh;
  position: relative;
}
.ProjectCard_ImageDiv {
  width: 100%;
}
.ProjectCard_Image {
  width: 60%;
  object-fit: cover;
  height: 100%;
  position: absolute;
  border-radius: 30px;
}
.ProjectCard_GradientDiv {
  position: absolute;
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  width: 60%;
  height: 100%;
  /* filter: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.1)); */
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}
.ProjectCard_TextDiv {
  position: absolute;
  width: 47%;
  height: 100%;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.ProjectCard_Heading {
  color: #003b7e;
  font-size: 3rem;
  width: 100%;
}
.ProjectCard_Desp {
  color: #7a7a7a;
  font-size: 1rem;
  width: 90%;
  margin: 3% 0;
}
.ProjectCard_TagsDiv {
  width: 90%;
  margin-top: 5%;
  color: #7a7a7a;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9rem;
}
.ProjectCard_Tags {
  margin-right: 2% !important;
}
.ProjectCard_Btn {
  color: #003b7e;
  font-size: 1.5rem;
  border: none;
  background-color: transparent;
  font-weight: 600;
  transition: 0.5s;
}
.ProjectCard_Btn:hover {
  margin-left: 3%;
}
@media only screen and (max-width: 1366px) {
  .ProjectCard_Heading {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 1024px) {
  .ProjectCard_MainDiv {
    width: 80%;
  }

  .ProjectCard_Image {
    width: 70%;
  }
  .ProjectCard_GradientDiv {
    width: 70%;
  }
  .ProjectCard_Desp {
    font-size: 0.9rem;
    width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .ProjectCard_Heading {
    font-size: 2rem;
    width: 95%;
  }
}
@media only screen and (max-width: 768px) {
  .ProjectCard_TextDiv {
    width: 60%;
  }
}
@media only screen and (max-width: 600px) {
  .ProjectCard_Container {
    height: 70vh;
    justify-content: flex-start;
    flex-direction: column;
  }
  .ProjectCard_MainDiv {
    width: 90%;
    height: 30vh;
    margin-top: 15%;
  }

  .ProjectCard_Image {
    width: 100%;
  }
  .ProjectCard_GradientDiv {
    background: linear-gradient(360deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    width: 100%;
  }
  .ProjectCard_TextDiv {
    width: 90%;
    left: 10%;
    top: 4rem;
  }
  .ProjectCard_Heading {
    font-size: 1.5rem;
    width: 100%;
  }
  .ProjectCard_Desp {
    font-size: 0.8rem;
  }

  .ProjectCard_Btn {
    font-size: 1.2rem;
  }
}
/* @media only screen and (max-width: 480px) {
} */
