.socialBannerContainer {
  padding: 5% 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #fef5e7 0%, #dafcfc 100%);
}
.socialBannerDiv {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.socialBannerLeftDiv {
  display: flex;
  align-items: center;
  width: 50%;
}
.socialBannerImageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
}
.socialBannerImageDiv img {
  width: 100%;
}
.socialBannerTextDiv {
  margin-left: 2%;
}
.socialBannerDivider {
  height: 3px;
  width: 25%;
  background-color: rgb(66, 194, 255);
  border-radius: 10px;
}
.socialBannerTextDiv p {
  margin: 4% 0px 2%;
  color: rgb(66, 194, 255);
  font-weight: 400;
  font-size: 1.4rem;
}
.socialBannerTextDiv h2 {
  color: rgb(0, 59, 126);
  font-size: 2rem;
  font-weight: 800;
}
.socialBannerRightDiv {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.socialBannerBtnDiv {
  width: 45%;
  padding: 2% 1% 2% 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(0, 59, 126);
  border-radius: 10px;
  transition: 0.5s;
  margin: 1% 0 0 0;
}
.socialBannerBtnDiv:hover {
  background-color: #0156b7;
  margin: 0 0 1% 0;
}
.socialBannerBtnImagediv {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.socialBannerBtnImagediv img {
  width: 100%;
}
.socialBannerBtnTextDiv {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.socialBannerBtnTextDiv p {
  color: rgb(255, 255, 255);
  font-weight: 700;
}
@media only screen and (max-width: 1660px) {
  .socialBannerContainer {
    padding: 5%;
  }
}
@media only screen and (max-width: 1366px) {
  .socialBannerContainer {
    padding: 7% 5%;
  }
  .socialBannerBtnDiv {
    width: 50%;
  }
}
@media only screen and (max-width: 1200px) {
  .socialBannerImageDiv {
    width: 20%;
  }
  .socialBannerDivider {
    width: 20%;
  }
  .socialBannerTextDiv p {
    font-size: 1.2rem;
  }
  .socialBannerTextDiv h2 {
    font-size: 1.7rem;
  }
  .socialBannerBtnDiv {
    width: 55%;
  }
}
@media only screen and (max-width: 1024px) {
  .socialBannerBtnDiv {
    width: 65%;
  }
  .socialBannerDivider {
    width: 20%;
  }
  .socialBannerTextDiv p {
    font-size: 1rem;
  }
  .socialBannerTextDiv h2 {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 900px) {
  .socialBannerBtnDiv {
    width: 75%;
    padding: 3% 1% 3% 3%;
  }
}
@media only screen and (max-width: 768px) {
  .socialBannerDiv {
    width: 95%;
  }
  .socialBannerLeftDiv {
    width: 60%;
  }
  .socialBannerRightDiv {
    width: 40%;
  }
  .socialBannerBtnDiv {
    width: 100%;
    padding: 3% 2% 3% 4%;
  }
  .socialBannerBtnTextDiv p {
    font-size: 0.9rem;
  }
  .socialBannerTextDiv p {
    font-size: 1rem;
    width: 90%;
  }
  .socialBannerTextDiv h2 {
    font-size: 1.2rem;
    width: 80%;
  }
}
@media only screen and (max-width: 600px) {
  .socialBannerContainer {
    display: none;
  }
}
