.ProjectListTabsBox {
  display: flex;
  width: 40%;
  justify-content: center;
}
.ProjectListTabs {
  width: 100% !important;
  overflow-x: scroll !important;
}
/* .ProjectsNavigateTabsBox {
  background-color: #fff;
  top: 60px;
  width: 100%;
  z-index: 1000;
} */
.ProjectsNavigateTabsBox {
  background-color: #fff;
  top: 60px;
  width: 100%;
  z-index: 1000;
}

.ProjectListMain {
  /* margin: 2%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProjectListLabel {
  font-family: Montserrat !important;
  color: black !important;
  padding: 1.5%;
  font-weight: 600 !important;
  opacity: 40%;
}

.ProjectListLabel:hover {
  color: black !important;
  background-color: #ced8e5 !important;
  opacity: 100% !important;
  border-radius: 10px !important;
}

.pjlabs {
  color: white !important;
  background-color: #003b7e !important;
  opacity: 100% !important;
  border-radius: 10px !important;
  transition: 0.75s !important;
}

.css-1aquho2-MuiTabs-indicator {
  height: 0 !important;
}
/* .css-1aquho2-MuiTabs-indicator {
    background: #003B7E !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
    color: #003B7E !important;

} */

.css-ttwr4n {
  height: 0 !important;
  width: 0 !important;
}

.css-19kzrtu {
  padding: 2%;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  margin: 0 0.5% !important;
  /* color: #8D8D8D !important */
}

/* @media only screen and (max-width: 1366px) {
  .ProjectsNavigateTabsBox {
    top: 60px;
  }
} */
.css-jpln7h-MuiTabs-scroller {
  overflow-x: scroll !important;
  max-width: 100% !important;
}
.css-jpln7h-MuiTabs-scroller::-webkit-scrollbar {
  /* visibility: hidden; */
  display: none;
}
.css-pc1mzd {
  overflow-x: scroll !important;
  max-width: 100% !important;
}

.css-1anid1y {
  overflow-x: scroll !important;
  max-width: 100% !important;
}
@media only screen and (max-width: 900px) {
  .ProjectsNavigateTabsBox {
    top: 50px !important;
  }
}
@media only screen and (max-width: 480px) {
  .ProjectsNavigateTabsBox {
    top: 45px !important;
  }
}
