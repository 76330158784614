.heroSectionContainer {
  height: 100vh;
  position: relative;
}
.heroSectionVideoDiv {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.heroSectionGradientDiv {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.heroSectionContentDiv {
  width: 90%;
  display: flex;
}
.heroSectionContentimage {
  width: 100%;
}
.heroSectionContentimage img {
  width: 100%;
}
.heroSectionLeftDiv {
  width: 100%;
}
.heroSectionRightDiv {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}
.heroSectionRightDiv img {
  display: none;
}
.heroSectionContentDivider {
  border-radius: 4px;
  background: var(--white, #003b7e);
  height: 8px;
  width: 10%;
}
.heroSectionLeftDiv h2 {
  color: var(--white, #003b7e);
  font-family: Raleway;
  font-size: 48px;
  font-weight: 800;
  margin: 3% 0;
  width: 100%;
}
.heroSectionLeftDiv p {
  color: var(--white, #7a7a7a);
  font-family: Raleway;
  font-size: 22px;
  font-weight: 400;
  margin: 2% 0 4% 0;
  width: 100%;
}
.heroSectionLeftDiv button {
  color: #003b7e;
  text-align: center;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 700;
  border-radius: 10px;
  border: 3px solid var(--white, #003b7e);
  background: #fff;
  display: flex;
  padding: 3% 6%;
  justify-content: center;
  align-items: center;
}
.heroSectionPlayVideoDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
}
.heroSectionPlayVideoDiv img {
  width: 60px;
  height: 60px;
}
.heroSectionPlayVideoDiv p {
  color: #f8f8f8;
  display: flex;
  align-items: center;
  width: fit-content;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
}
.heroSectionModalContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.9) 100%);
}
.heroSectionModalDiv {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.heroSectionModalClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.heroSectionModalDiv video {
  width: 100%;
  height: 100%;
}
.heroSectionBtnContainer {
  width: 100%;
  display: flex;
  gap: 3%;
}
.heroSectionBtnLink {
  width: 17%;
}
.heroSectionBtn {
  font-size: 16px !important;
  font-weight: 700 !important;
  font-family: Raleway !important;
  color: #003b7e !important;
  border: 2px solid #003b7e !important;
  margin: 5% 0 !important;
  border-radius: 8px !important;
  padding: 8% 0 !important;
  width: 100%;
  transition: 0.5s !important;
  background: transparent !important;
}
.heroSectionBtn:hover {
  color: #003b7e !important;
  background-color: #fff !important;
  margin: 4% 0 6% 0 !important;
}
@media only screen and (max-width: 1728px) {
  .heroSectionBtnLink {
    width: 18%;
  }
}

@media only screen and (max-width: 1500px) {
  .heroSectionBtnLink {
    width: 30%;
  }
  .heroSectionContentDivider {
    height: 6px;
    width: 20%;
  }
  .heroSectionLeftDiv h2 {
    width: 100%;
  }
  .heroSectionLeftDiv p {
    width: 100%;
  }
  .heroSectionPlayVideoDiv {
    width: 30%;
  }
}
@media only screen and (max-width: 1366px) {
  .heroSectionBtnLink {
    width: 35%;
  }
  .heroSectionLeftDiv h2 {
    width: 100%;
    font-size: 36px;
  }
  .heroSectionLeftDiv p {
    width: 100%;
    font-size: 18px;
  }
  .heroSectionPlayVideoDiv {
    width: 35%;
  }
  .heroSectionModalDiv {
    width: 80%;
  }
}
@media only screen and (max-width: 1200px) {
  .heroSectionBtnLink {
    width: 36%;
  }
  .heroSectionLeftDiv h2 {
    font-size: 32px;
  }
  .heroSectionLeftDiv p {
    font-size: 16px;
  }
  .heroSectionPlayVideoDiv p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1024px) {
  .heroSectionBtnLink {
    width: 40%;
  }
  .heroSectionBtn {
    font-size: 14px !important;
  }
  .heroSectionLeftDiv h2 {
    font-size: 28px;
  }
  .heroSectionLeftDiv p {
    font-size: 15px;
  }
  .heroSectionPlayVideoDiv {
    width: 40%;
  }
}
@media only screen and (max-width: 900px) {
  .heroSectionBtnLink {
    width: 40%;
  }
  .heroSectionLeftDiv h2 {
    width: 100%;
    font-size: 28px;
  }
  .heroSectionLeftDiv p {
    width: 100%;
    font-size: 16px;
  }
  .heroSectionPlayVideoDiv {
    width: 50%;
  }
  .heroSectionModalDiv {
    width: 85%;
  }
}
@media only screen and (max-width: 768px) {
  .heroSectionContentDiv {
    flex-direction: column-reverse;
  }
  .heroSectionContentimage {
    margin-bottom: 10%;
  }
  .heroSectionContentDivider {
    width: 25%;
  }
  .heroSectionBtnLink {
    width: 40%;
  }
  .heroSectionBtnContainer {
    gap: 0;
    flex-direction: column;
    align-items: left;
  }
  .heroSectionLeftDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: left;
  }
  .heroSectionPlayVideoDiv {
    justify-content: center;
    text-align: center;
    width: 50%;
  }
  .heroSectionPlayVideoDiv p {
    width: fit-content !important;
  }
  .heroSectionPlayVideoDiv img {
    width: 50px;
    height: 50px;
  }
  .heroSectionModalDiv {
    width: 90%;
  }
}
@media only screen and (max-width: 600px) {
  .heroSectionBtnLink {
    width: 50%;
  }
  .heroSectionLeftDiv h2 {
    width: 90%;
  }
  .heroSectionLeftDiv p {
    width: 90%;
  }
  .heroSectionPlayVideoDiv {
    width: 60%;
    margin: 5% 0 0 0;
  }

  .heroSectionPlayVideoDiv img {
    width: 40px;
    height: 40px;
  }
}
@media only screen and (max-width: 480px) {
  .heroSectionPlayVideoDiv {
    width: 70%;
  }
  .heroSectionBtnLink {
    width: 65%;
  }
  .heroSectionLeftDiv h2 {
    font-size: 24px;
    width: 100%;
  }
  .heroSectionLeftDiv p {
    font-size: 14px;
    width: 100%;
  }
}
