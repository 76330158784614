.ClientSectionMain {
  height: 60vh;
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.ClientSectionTitleDiv {
  padding: 5%;
  width: 30%;
  z-index: 11 !important;
}

.ClientsDiv {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 100%;
  width: 100%;
}

.ClientsGradientDiv {
  position: absolute !important;
  height: 100%;
  width: 100vw;
  z-index: 10 !important;
  background: linear-gradient(100.19deg, #ffffff 25.54%, rgba(255, 255, 255, 0) 98.48%) !important;
}
.ClientsGradientImg {
  position: absolute;
  object-fit: cover;
  width: 180%;
}

.ClientsMovingImg {
  width: 100%;
  animation-name: myanimay;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 10s;
}

@keyframes myanimay {
  from {
    transform: translate(0px, 100px);
  }
  to {
    transform: translate(100px, 0px);
  }
}

@media only screen and (max-width: 1366px) {
  .ClientSectionTitleDiv {
    width: 40%;
  }
}
@media only screen and (max-width: 1200px) {
  .ClientSectionTitleDiv {
    width: 50%;
  }
  .ClientsGradientImg {
    width: 250%;
  }
}
@media only screen and (max-width: 768px) {
  .ClientsMovingImg {
    width: 150%;
  }
  .ClientSectionTitleDiv {
    width: 60%;
  }
  .ClientsGradientImg {
    width: 300%;
  }
}
@media only screen and (max-width: 600px) {
  .ClientSectionTitleDiv {
    width: 100%;
    padding: 3% 0 0 0;
    background-color: #ffffff;
  }
  .ClientLogoGrid {
    margin: 5% 0;
  }
  .ClientsGradientImg {
    width: 350%;
  }
}
@media only screen and (max-width: 480px) {
  .ClientsGradientImg {
    width: 350%;
  }
}
