.LocationSectionMain{
  height: 100%;
}
.LocationSliderDiv{
    width: 100%;
    /* height: 50vh; */
  padding: 2%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

}
.LocationCarouselDiv{
    width: 95%;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between  !important;
}
.LocationDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0% 10%;

}
.LocationDiv_Typo{
    width: 45%;
    text-align: left;
    /* margin-left: 2%; */
}
.LocationDiv_Typo img{
    width: 25% !important;
}
.Location_Country{
    color: #003B7E ;
    font-weight: 700 !important;
    font-size: 1.8rem !important;
    margin: 1% 0% !important;
}
.Location_Address{
    color: #282828;
    font-weight: 600  !important;
    font-size: 1rem !important;
    width: 80%;
    
}
.LocationDiv_Img{
    width: 55%;
}
.Location_Img{
    width: 100%;
}



@media only screen and (max-width: 600px) {
    .Awesome_Slider{
        height: 100% !important;
        margin-top: 0 !important;
    }
    .LocationDiv{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 10% !important;
    
    }
    .LocationDiv_Img{
        width: 85% !important;
    }
    .LocationDiv_Typo{
        width: 85%;
        text-align: center;
        margin-bottom: 5%;
        /* margin-left: 2%; */
    }
    .Location_Address{
        width: 100%;
        
    }
    .LocationDiv_Typo img{
        visibility: hidden;
        position: absolute;

}
}
@media only screen and (max-width: 520px) {
    
    .LocationDiv{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0%;
    
    }
    .LocationDiv_Img{
        width: 75% !important;
    }
    .LocationDiv_Typo{
        width: 85%;
        text-align: center;
        margin-bottom: 5%;
        /* margin-left: 2%; */
    }
    .Location_Country{
        font-size: 1.5rem !important;
    }
    .Location_Address{
        font-size: 1rem !important;

        
    }
    .LocationDiv_Typo img{
        visibility: hidden;
        position: absolute;

}
.awssld__controls{
    visibility: visible !important;
}
}