.testimonialV2Container {
  width: 100%;
  padding: 5% 0;
  background: linear-gradient(90deg, #fef5e7 0%, #dafcfc 100%);
  position: relative;
}
.testimonialV2TitleDiv {
  padding: 0 5%;
  width: 100%;
  display: flex;
}
.testimonialV2Title {
  width: 70%;
}
.testimonialV2Title h3 {
  color: #0f52ba;
  font-family: Raleway;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 1.08px;
}
.testimonialV2Title p {
  color: #7a7a7a;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  margin: 1% 0;
}
.testimonialV2ThumbsUpDiv {
  width: 10%;
}
.testimonialV2ThumbsUpDiv img {
  position: absolute;
  top: -50px;
  width: 20%;
  right: 10%;
}
.testimonialV2ReviewSection {
  width: 100%;
  margin-top: 4%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.testimonialV2ReviewDiv {
  width: 23%;
  margin: 1% 0;
}
.testimonialV2BtnDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}
.testimonialV2BtnDiv button {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  padding: 1% 0;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 3px solid #003b7e;
  background: #003b7e;
  gap: 8%;
  width: 13%;
}
@media only screen and (max-width: 1500px) {
  .testimonialV2Title h3 {
    font-size: 32px;
  }
  .testimonialV2Title p {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1366px) {
  .testimonialV2Title h3 {
    font-size: 28px;
  }
  .testimonialV2Title p {
    font-size: 16px;
  }
  .testimonialV2BtnDiv button {
    width: 15%;
  }
}
@media only screen and (max-width: 1200px) {
  .testimonialV2ThumbsUpDiv img {
    top: -30px;
  }
  .testimonialV2BtnDiv button {
    width: 17%;
  }
}
@media only screen and (max-width: 1024px) {
  .testimonialV2Title h3 {
    font-size: 24px;
  }
  .testimonialV2Title p {
    width: 80%;
  }
  .testimonialV2ThumbsUpDiv img {
    top: -30px;
  }
  .testimonialV2ReviewSection {
    justify-content: space-evenly;
  }
  .testimonialV2ReviewDiv {
    width: 32%;
  }
  .testimonialV2BtnDiv button {
    width: 20%;
  }
}
@media only screen and (max-width: 900px) {
  .testimonialV2ThumbsUpDiv img {
    width: 25%;
  }
  .testimonialV2ReviewDiv {
    width: 40%;
  }
  .testimonialV2BtnDiv button {
    width: 25%;
  }
}
@media only screen and (max-width: 768px) {
  .testimonialV2TitleDiv {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .testimonialV2Title {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .testimonialV2ThumbsUpDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .testimonialV2ThumbsUpDiv img {
    position: relative;
    width: 25%;
    right: auto;
    top: 0;
  }
  .testimonialV2ReviewDiv {
    width: 44%;
  }
  .testimonialV2BtnDiv button {
    width: 30%;
  }
}
@media only screen and (max-width: 600px) {
  .testimonialV2Title {
    width: 100%;
  }
  .testimonialV2Title p {
    width: 100%;
  }
  .testimonialV2ThumbsUpDiv img {
    width: 35%;
  }
  .testimonialV2ReviewDiv {
    width: 70%;
    margin: 2% 0;
  }
  .testimonialV2BtnDiv button {
    width: 38%;
  }
}
@media only screen and (max-width: 480px) {
  .testimonialV2ThumbsUpDiv img {
    width: 45%;
  }
  .testimonialV2ReviewDiv {
    width: 90%;
    margin: 2% 0;
  }
  .testimonialV2BtnDiv button {
    width: 50%;
  }
}
@media only screen and (max-width: 380px) {
  .testimonialV2BtnDiv button {
    width: 60%;
  }
}
