.DetailAppDev_Container {
  width: 100%;
  padding: 5% 5% 0 5%;
}
.DetailAppDev_TitleDiv {
  display: flex;
  align-items: center;
}
.DetailAppDev_TitleDivider {
  width: 7%;
  height: 2px;
  margin-right: 1% !important;
}
.DetailAppDev_Title {
  font-weight: 700 !important;
  font-size: 1.5rem !important;
}
.DetailAppDev_Desp {
  font-weight: 500 !important;
  margin-top: 1.5% !important;
  font-size: 1.1rem !important;
  text-align: justify;
}
.DetailAppDev_Img {
  width: 100%;
}
.DetailAppDev_ImgDiv {
  padding-top: 5%;
  /* margin-bottom: -5px; */
}
.HalfImg {
  padding: 2% 5%;
}
.DetailAppDev_ImgTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3%;
}
.DetailAppDev_ImgTitle h3 {
  font-family: Raleway;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.9px;
  text-align: center;
  padding-bottom: 1%;
}
