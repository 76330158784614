.DetailHeader_Container {
  height: 85vh;
  overflow: hidden;
  width: 100%;
}
.DetailHeader_MainDiv {
  position: relative;
  width: 100%;
}
.DetailHeader_Img {
  height: 85vh;
  width: 100%;
  opacity: 0.7;
  object-fit: cover;
}
.DetailHeader_Typo {
  position: absolute;
  top: 25%;
  left: 38%;
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DetailHeaderTitleDiv {
  width: 40%;
  display: flex;
  flex-direction: column;
}
.DetailHeader_TypoMob {
  display: none;
}
.DetailHeader_Title {
  font-size: 2.5rem;
  width: 95% !important;
  color: #fff;
  background: transparent;
}
.DetailHeader_Divider {
  width: 20%;
  height: 4px;
  border-radius: 4px;
  margin: 2% 0 !important;
}
.DetailHeader_DespDiv {
  width: 60%;
  padding: 5%;
  border-radius: 10px;
  backdrop-filter: blur(25px);
}
.DetailHeader_SubTitle {
  font-size: 1.2rem !important;
  color: #f8f8f8;
  font-weight: 600 !important;
  margin: 2% 0 !important;
}
.DetailHeader_Desp {
  color: #f8f8f8;
  font-family: Raleway;
  text-align: justify;
}
.DetailHeader_BottomDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
}
.DetailHeader_DespMob {
  display: none;
}
@media only screen and (max-width: 1500px) {
  .DetailHeader_Typo {
    top: 20%;
    left: 35%;
    width: 60%;
  }
  .DetailHeader_DespDiv {
    width: 80%;
    padding: 5%;
    border-radius: 10px;
  }
}
@media only screen and (max-width: 1200px) {
  .DetailHeader_Typo {
    left: 25%;
    width: 70%;
  }
}
@media only screen and (max-width: 900px) {
  .DetailHeader_Typo {
    left: 5%;
    width: 90%;
    flex-direction: column;
  }
  .DetailHeaderTitleDiv {
    width: 90%;
    align-items: center;
    text-align: center;
    margin-bottom: 3%;
  }
  .DetailHeader_Divider {
    width: 10%;
    margin: 1% 0 !important;
  }
}
@media only screen and (max-width: 600px) {
  .DetailHeader_Typo {
    display: none;
  }
  .DetailHeader_Container {
    height: 100%;
  }
  .DetailHeader_DespMob {
    display: block;
    padding: 7% 10%;
    width: 100%;
    height: 43vh;
  }
  .DetailHeader_Img {
    height: 50vh;
  }
  .DetailHeader_BottomDiv {
    height: 10vh;
  }
  .DetailHeader_TypoMob {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    top: 40%;
  }
}
@media only screen and (max-width: 480px) {
  .DetailHeader_DespMob {
    height: 48vh;
  }
}
