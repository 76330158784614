.aboutTeamV2Container {
  width: 100%;
  padding: 5%;
}
.aboutTeamV2HeaderDiv {
  width: 100%;
}
.aboutTeamV2HeaderDiv h3 {
  width: 100%;
  color: #42c2ff;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.aboutTeamV2HeaderDiv h2 {
  width: 100%;
  color: #0f52ba;
  font-family: Raleway;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 1.08px;
  margin: 0.5% 0 1% 0;
}
.aboutTeamV2HeaderDiv p {
  width: 60%;
  color: #7a7a7a;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
}
.aboutTeamV2CardContainer {
  width: 100%;
  margin-top: 4%;
  display: flex;
  /* justify-content:; */
  gap: 0.8%;
  flex-wrap: wrap;
}
.aboutTeamV2Card {
  width: 16%;
  margin-bottom: 3%;
}

/* team card Css */
.aboutTeamCardDiv {
  width: 100%;
}
.aboutTeamCardImg {
  width: 100%;
  background-color: #ecf9ff;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}
.aboutTeamCardImg img {
  width: 100%;
  transition: 0.3s ease;
  transform: translateY(5%);
  filter: grayscale(50%);
}
.aboutTeamCardImg:hover img {
  transform: translateY(0);
  filter: grayscale(0%);
}

.aboutTeamCardInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 5%;
}
.aboutTeamCardInfo h3 {
  color: #2a3342;
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-weight: 700;
  padding: 3% 0 2% 0;
}
.aboutTeamCardInfo p {
  color: #42c2ff;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}

@media only screen and (max-width: 1500px) {
  .aboutTeamV2HeaderDiv p {
    width: 80%;
  }
}
@media only screen and (max-width: 1366px) {
  .aboutTeamV2HeaderDiv h2 {
    font-size: 32px;
  }
  .aboutTeamV2HeaderDiv p {
    font-size: 18px;
  }
  .aboutTeamCardInfo h3 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 1200px) {
  .aboutTeamCardInfo h3 {
    font-size: 20px;
  }
  .aboutTeamCardInfo p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1024px) {
  .aboutTeamV2HeaderDiv h3 {
    font-size: 18px;
  }
  .aboutTeamV2HeaderDiv h2 {
    font-size: 28px;
  }
  .aboutTeamV2HeaderDiv p {
    width: 90%;
    font-size: 16px;
  }
  .aboutTeamCardInfo h3 {
    font-size: 18px;
    padding: 3% 0 0 0;
  }
  .aboutTeamCardInfo p {
    font-size: 12px;
  }
}
@media only screen and (max-width: 900px) {
  .aboutTeamV2HeaderDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .aboutTeamV2HeaderDiv h3 {
    font-size: 16px;
  }
  .aboutTeamV2HeaderDiv h2 {
    font-size: 24px;
  }
  .aboutTeamV2HeaderDiv p {
    font-size: 14px;
  }

  .aboutTeamV2CardContainer {
    margin: 6% 0 2% 0;
    justify-content: space-evenly;
  }
  .aboutTeamV2Card {
    width: 23%;
  }
}
@media only screen and (max-width: 768px) {
  .aboutTeamV2HeaderDiv h3 {
    font-size: 14px;
  }
  .aboutTeamV2HeaderDiv h2 {
    font-size: 22px;
  }
  .aboutTeamV2HeaderDiv p {
    font-size: 12px;
  }
  .aboutTeamCardInfo h3 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .aboutTeamV2HeaderDiv h2 {
    font-size: 20px;
  }
  .aboutTeamV2HeaderDiv p {
    width: 100%;
  }
  .aboutTeamCardInfo p {
    font-size: 10px;
  }
}
@media only screen and (max-width: 520px) {
  .aboutTeamV2Card {
    width: 40%;
  }
}
@media only screen and (max-width: 480px) {
  .aboutTeamV2HeaderDiv h2 {
    font-size: 20px;
    margin: 1% 0 2% 0;
  }
  .aboutTeamV2HeaderDiv p {
    width: 100%;
  }
  .aboutTeamV2Card {
    width: 45%;
  }
  .aboutTeamV2CardContainer {
    margin: 6% 0 2% 0;
    justify-content: space-evenly;
  }
}
