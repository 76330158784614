.aboutBannerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7% 0;
  background: url(../../assets/images/contact_section_bg.png),
    linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100.49%), #003b7e;
  background-size: cover;
  background-blend-mode: multiply, normal, normal;
  overflow: hidden;
}
.aboutBannerContainer h2 {
  color: #f8f8f8;
  font-family: Raleway;
  font-size: 48px;
  font-weight: 800;
  letter-spacing: 1.44px;
  width: 100%;
  text-align: center;
}
.aboutBannerContainer h2 span {
  color: #42c2ff;
}
@media only screen and (max-width: 1500px) {
  .aboutBannerContainer h2 {
    font-size: 42px;
  }
}
@media only screen and (max-width: 1366px) {
  .aboutBannerContainer h2 {
    font-size: 38px;
  }
}
@media only screen and (max-width: 1200px) {
  .aboutBannerContainer h2 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 1024px) {
  .aboutBannerContainer h2 {
    font-size: 28px;
  }
}
@media only screen and (max-width: 900px) {
  .aboutBannerContainer h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 768px) {
  .aboutBannerContainer h2 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 600px) {
  .aboutBannerContainer h2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 520px) {
  .aboutBannerContainer {
    padding: 10% 0;
  }
  .aboutBannerContainer h2 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 480px) {
  .aboutBannerContainer h2 {
    font-size: 14px;
  }
}
