.aboutHeaderV2Container {
  display: flex;
  justify-content: space-between;
  padding: 7.5% 3% 5% 3%;
  align-items: center;
  width: 100%;
}
.aboutHeaderV2ContentDiv {
  padding: 7%;
}
.aboutHeaderV2Header {
  width: 100%;
}
.aboutHeaderV2Header h1 {
  color: #0f52ba;
  font-family: Raleway;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 1.08px;
}
.aboutHeaderV2Header p {
  color: #7a7a7a;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  margin: 5% 0;
}
.aboutHeaderV2IconsDiv {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1%;
}

.aboutHeaderV2Icons {
  width: 100%;
  padding: 10% 0 0 0;
  transition: 0.3s;
}
.aboutHeaderV2Icons:hover {
  padding: 0 0 10% 0;
}
.aboutHeaderV2HeaderImgGrid {
  display: flex;
  justify-content: center;
  width: 100%;
}
.aboutHeaderV2HeaderImg {
  width: 100%;
}
@media only screen and (max-width: 1500px) {
  .aboutHeaderV2Header h1 {
    font-size: 28px;
  }
  .aboutHeaderV2Header p {
    font-size: 16px;
  }
  .aboutHeaderV2IconsDiv {
    gap: 0.5%;
  }
  .aboutHeaderV2Icons {
    width: 90%;
  }
}
@media only screen and (max-width: 1366px) {
  .aboutHeaderV2Container {
    padding: 12% 3% 5% 3%;
  }
}
@media only screen and (max-width: 1200px) {
  .aboutHeaderV2Header p {
    font-size: 14px;
  }
  .aboutHeaderV2IconsDiv {
    gap: 0.1%;
  }
  .aboutHeaderV2Icons {
    width: 80%;
  }
}
@media only screen and (max-width: 1024px) {
  .aboutHeaderV2Header p {
    font-size: 12px;
  }
}
@media only screen and (max-width: 899px) {
  .aboutHeaderV2Container {
    flex-direction: column-reverse !important;
  }
  .aboutHeaderV2HeaderImg {
    width: 70%;
  }
  .aboutHeaderV2Header p {
    font-size: 16px;
  }
  .aboutHeaderV2Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .aboutHeaderV2IconsDiv {
    justify-content: center;
  }
}
@media only screen and (max-width: 768px) {
  .aboutHeaderV2HeaderImg {
    width: 85%;
  }
  .aboutHeaderV2Header p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 600px) {
  .aboutHeaderV2HeaderImg {
    width: 95%;
  }
  .aboutHeaderV2Header p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 600px) {
  .aboutHeaderV2HeaderImg {
    width: 95%;
  }
  .aboutHeaderV2Header p {
    font-size: 14px;
    text-align: justify;
  }
}
@media only screen and (max-width: 480px) {
  .aboutHeaderV2HeaderImg {
    width: 100%;
  }
  .aboutHeaderV2Header p {
    font-size: 16px;
  }
}
