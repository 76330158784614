.visionSectionV2Container {
  width: 100%;
  padding: 5%;
  display: flex;
  justify-content: space-between;
}
.visionSectionV2LeftDiv {
  width: 32%;
}
.visionSectionV2LeftDiv h3 {
  color: #42c2ff;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.visionSectionV2LeftDiv h2 {
  color: #0f52ba;
  font-family: Raleway;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 1.08px;
  margin: 1% 0 3% 0;
}
.visionSectionV2LeftDiv p {
  color: #7a7a7a;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
}
.visionSectionV2Btn {
  border-radius: 10px;
  background-color: rgb(0, 59, 126);
  display: flex;
  padding: 3% 0;
  width: 30%;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 700;
  margin: 8% 0 0 0;
  border: none;
  transition: 0.5s;
}
.visionSectionV2Btn:hover {
  background-color: #0156b7;
  margin: 7% 0 1% 0;
}
.visionSectionV2RightDiv {
  width: 65%;
  display: flex;
  gap: 2%;
  flex-wrap: wrap;
}
.visionSectionV2TagsDiv {
  width: 32%;
  display: flex;
  /* align-items: center; */
  margin-bottom: 3%;
}

.visionTagDiv {
  width: 100%;
  /* padding: 0; */
}
.visionTagTitleDiv {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5%;
}
.visionTagTitleDiv h3 {
  color: #454238;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}
.visionTagDesp {
  width: 100%;
}
.visionTagDesp p {
  width: 100%;
  color: #282828;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  margin-top: 5%;
}
@media only screen and (max-width: 1500px) {
  .visionSectionV2LeftDiv h3 {
    font-size: 18px;
  }
  .visionSectionV2LeftDiv h2 {
    font-size: 32px;
  }
  .visionSectionV2LeftDiv p {
    font-size: 18px;
  }
  .visionTagTitleDiv h3 {
    font-size: 16px;
  }
  .visionTagDesp p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1366px) {
  .visionSectionV2LeftDiv h2 {
    font-size: 28px;
  }
  .visionSectionV2LeftDiv p {
    font-size: 16px;
  }
  .visionSectionV2LeftDiv button {
    width: 35%;
  }
}
@media only screen and (max-width: 1024px) {
  .visionSectionV2RightDiv {
    justify-content: space-evenly;
  }
  .visionSectionV2TagsDiv {
    width: 45%;
  }
  .visionSectionV2LeftDiv button {
    width: 45%;
  }
}
@media only screen and (max-width: 900px) {
  .visionSectionV2Container {
    justify-content: center;
    flex-direction: column;
  }
  .visionSectionV2LeftDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 2% 0;
  }
  .visionSectionV2LeftDiv p {
    font-size: 16px;
    width: 90%;
  }
  .visionSectionV2LeftDiv button {
    padding: 2% 0;
    width: 20%;
    font-size: 16px;
    margin: 2%;
  }
  .visionSectionV2RightDiv {
    width: 100%;
    margin: 2% 0;
  }
  .visionSectionV2TagsDiv {
    width: 30%;
    margin: 4% 0;
  }
}
@media only screen and (max-width: 768px) {
  .visionSectionV2TagsDiv {
    width: 45%;
  }
}
@media only screen and (max-width: 600px) {
  .visionSectionV2LeftDiv h3 {
    font-size: 16px;
  }
  .visionSectionV2LeftDiv h2 {
    font-size: 24px;
  }
  .visionSectionV2LeftDiv p {
    font-size: 14px;
  }
  .visionSectionV2LeftDiv button {
    width: 35%;
  }
  .visionTagTitleDiv h3 {
    font-size: 14px;
  }
  .visionTagDesp p {
    font-size: 12px;
  }
}
@media only screen and (max-width: 480px) {
  .visionSectionV2LeftDiv h2 {
    font-size: 24px;
  }
  .visionSectionV2LeftDiv p {
    font-size: 14px;
    text-align: justify;
  }
  .visionSectionV2LeftDiv button {
    width: 40%;
    font-size: 16px;
    margin: 5% 0;
    padding: 3% 0;
    border-radius: 50px;
  }

  .visionSectionV2TagsDiv {
    width: 100%;
    margin: 5% 0;
  }

  .visionTagTitleDiv {
    justify-content: center;
    gap: 3%;
  }
  .visionTagTitleDiv h3 {
    font-size: 18px;
  }

  .visionTagDesp {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .visionTagDesp p {
    width: 60%;
    text-align: center;
    font-size: 14px;
    margin-top: 2%;
  }
}
