.blogSectionV2Container {
  width: 100%;
  padding: 5%;
}
.blogSectionV2Header {
  width: 100%;
}
.blogSectionV2Header h4 {
  width: 100%;
  color: #42c2ff;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.blogSectionV2Header h3 {
  width: 100%;
  color: #003b7e;
  font-family: Raleway;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 1.08px;
  margin: 0.5% 0;
}
.blogSectionV2ContentDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 4%;
}
.blogSectionV2CardDiv {
  width: 32%;
}
@media only screen and (max-width: 1200px) {
  .blogSectionV2Header h4 {
    font-size: 18px;
  }
  .blogSectionV2Header h3 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 1024px) {
  .blogSectionV2Header h4 {
    font-size: 16px;
  }
  .blogSectionV2Header h3 {
    font-size: 28px;
  }
}
@media only screen and (max-width: 900px) {
  .blogSectionV2Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .blogSectionV2Header h4 {
    width: 100%;
    font-size: 14px;
  }
  .blogSectionV2Header h3 {
    width: 100%;
    font-size: 24px;
  }
}
@media only screen and (max-width: 768px) {
  .mobDisplay {
    display: none;
  }
  .blogSectionV2CardDiv {
    width: 40%;
  }
  .blogSectionV2ContentDiv {
    justify-content: space-evenly;
  }
}
@media only screen and (max-width: 480px) {
  .blogSectionV2CardDiv {
    width: 90%;
    margin: 2% 0 4% 0;
  }
  .blogSectionV2ContentDiv {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .blogSectionV2Header h3 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 380px) {
  .blogSectionV2CardDiv {
    width: 100%;
  }
}
