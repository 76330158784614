.adContactConatiner {
  width: 100%;
  padding: 8% 5% 3% 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: url(../../assets/images/adBg.png),
    linear-gradient(147deg, rgba(255, 255, 255, 0) 10.01%, #ebf8ff 100%);
  background-size: cover;
  background-repeat: no-repeat;
}
.adContactVideoSection {
  width: 40%;
}
.adContactDivider {
  height: 8px;
  width: 20%;
  border-radius: 4px;
  background: #003b7e;
  margin-bottom: 4%;
}
.adContactVideoSection h1 {
  color: #003b7e;
  font-family: Raleway;
  font-size: 48px;
  font-weight: 800;
  margin: 2% 0;
}
.adContactVideoDiv {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.adContactVideoThumbnail {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.adContactVideoPlayIcon {
  position: absolute;
  cursor: pointer;
  width: 80px;
  height: 80px;
}

.adContactFormDiv {
  width: 55%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(25, 157, 125, 0.1);
}
.adContactSubmitContainer {
  width: 100%;
  padding: 2% 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.adContactInputDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5% 0;
}
.AdContactV2ModalContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.9) 100%);
  z-index: 1;
}
.AdContactV2ModalDiv {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.AdContactV2ModalClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.AdContactV2ModalDiv video {
  width: 100%;
  height: 100%;
}
.AdContactV2ModalDiv iframe {
  width: 100%;
  height: 60vh;
}
.adContactFormHeading {
  display: none;
}
.adContactContactSection_Button {
  padding: 2% 5%;
  background: #003b7e;
  opacity: 0.5;
  border: 3px solid #7c99bb;
  border-radius: 10px;
  color: #f8f8f8 !important;
  font-weight: 700;
  font-size: 1rem;
  transition: 0.5s;
  transform: scale(1);
  margin: 1% 0%;
}
.adContactContactSection_ButtonHover {
  padding: 2% 5%;
  background: #003b7e;
  border: 3px solid #7c99bb;
  border-radius: 10px;
  color: #f8f8f8 !important;
  font-weight: 700;
  font-size: 1rem;
  transition: 0.5s;
  margin: 1% 0%;
}
.adContactContactSection_ButtonHover:hover {
  background: #0156b7;
  color: #f8f8f8 !important;
  margin: 0 0 2% 0;

  border: 3px solid #0156b7 !important;
}
@media only screen and (max-width: 1500px) {
  .adContactVideoSection {
    width: 45%;
  }
  .adContactVideoSection h1 {
    font-size: 38px;
  }
  .adContactFormDiv {
    width: 50%;
  }
}
@media only screen and (max-width: 1366px) {
  .adContactVideoSection {
    width: 42%;
  }
  .adContactFormDiv {
    width: 53%;
  }
}
@media only screen and (max-width: 1200px) {
  .adContactContactSection_Button {
    padding: 2% 4%;
    font-size: 14px;
  }
  .adContactContactSection_ButtonHover {
    padding: 2% 4%;
    font-size: 14px;
  }
}
@media only screen and (max-width: 1200px) {
  .adContactVideoSection h1 {
    font-size: 32px;
  }
  .AdContactFont {
    font-size: 18px;
  }
  .adContactVideoPlayIcon {
    width: 60px;
    height: 60px;
  }
}
@media only screen and (max-width: 1024px) {
  .adContactConatiner {
    padding: 10% 5% 3% 5%;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
  .adContactVideoSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .adContactDivider {
    margin-bottom: 1%;
  }
  .adContactVideoSection h1 {
    margin: 2% 0 4% 0;
    text-align: center;
  }
  .adContactVideoDiv {
    width: 80%;
  }
  .adContactFormDiv {
    width: 80%;
    padding: 2% 3%;
  }
  .adContactFormHeading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% 0 2% 0;
  }
  .adContactFormHeading h3 {
    color: #003b7e;
    font-family: Raleway;
    font-size: 38px;
    font-weight: 800;
  }
  .adContactVideoPlayIcon {
    width: 80px;
    height: 80px;
  }
}
@media only screen and (max-width: 900px) {
  .adContactVideoDiv {
    width: 70%;
  }
  .adContactFormDiv {
    width: 70%;
  }
  .adContactVideoPlayIcon {
    width: 60px;
    height: 60px;
  }
}
@media only screen and (max-width: 768px) {
  .adContactVideoSection h1 {
    font-size: 28px;
  }
  .adContactFormHeading h3 {
    font-size: 28px;
  }
  .adContactVideoDiv {
    width: 80%;
  }
  .adContactFormDiv {
    width: 80%;
  }
  .adContactDivider {
    height: 5px;
  }
  .adContactReCaptchaDiv {
    margin: 1% 0 2% 0;
  }
  .adContactSubmitContainer {
    align-items: flex-start;
    flex-direction: column;
  }
}
@media only screen and (max-width: 600px) {
  .adContactConatiner {
    padding: 15% 5% 3% 5%;
  }
  .adContactVideoSection h1 {
    font-size: 24px;
  }
  .adContactFormHeading h3 {
    font-size: 24px;
  }
  .AdContactFont {
    font-size: 16px;
  }
  .adContactVideoDiv {
    width: 100%;
  }
  .adContactFormDiv {
    width: 100%;
    padding: 3% 5%;
  }

  .adContactVideoPlayIcon {
    width: 50px;
    height: 50px;
  }
}
@media only screen and (max-width: 480px) {
  .AdContactFont {
    font-size: 14px;
  }
}
