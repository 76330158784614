.discoverMainDiv {
  width: 100%;
  margin: 60px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.discoverSubDiv {
  width: 90%;
  display: flex;
  justify-content: center;
  padding: 3% 0;
  align-items: center;
}

.discoverTextDiv {
  text-align: center;
}

.discoverTextDiv h3 {
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #42c2ff;
}

.discoverTextDiv h1 {
  font-size: 48px;
  font-weight: bold;
  margin: 1% 0;
  color: #003b7e;
}

.discoverTextDiv h2 {
  color: black;
  font-family: Raleway;
  font-size: 22px;
  font-weight: 400;
  margin: 2% 0 4% 0;
  width: 100%;
}

/* video section css */
.discoverVideoMainDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.discoverVideoSubDiv {
  width: 90%;
}

.videoContainer {
  /* height: fit-content; */
  /* width: 100%; */
  /* object-fit: contain; */
  position: relative;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 12px 46px 0px rgba(180, 199, 207, 0.5);
  padding: 15px;
}

.videoContainer video {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.AboutSectionV2VideoDiv {
  width: 50%;
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 12px 46px 0px rgba(180, 199, 207, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.playButton {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: transparent;
  width: fit-content;
  border: none;
  cursor: pointer;
}

.discoverVideoButton {
  width: 100px;
}

/* form css */
.discoverFormModal {
  background-color: #ffffff;
  width: 40%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 12px 46px 0px rgba(180, 199, 207, 0.5);
  border-radius: 40px;
  border: solid 10px #0e3b7d;
}
.discoverFormModal:focus-visible {
  outline: none;
}
.discoverFromDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5% 0;
  position: relative;
  width: 80%;
}

.discoverFormInputDiv label {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  font-size: 20px;
  color: #003b7e;
  font-weight: 500;
}

.discoverFormInputDiv {
  width: 100%;
  padding: 3% 0;
  margin: 1% 0;
}

.discoverFormInputDiv input {
  margin: 2% 0;
  padding: 1%;
  border-radius: 12px;
  font-size: 20px;
}

.discoverFromDiv h1 {
  padding-bottom: 5%;
  font-size: 30px;
  font-weight: bold;
  margin: 1% 0;
  color: #003b7e;
}

.discoverFromDiv p {
  text-align: center;
}

.discoverFromDiv button {
  border-radius: 10px;
  background-color: rgb(0, 59, 126);
  display: flex;
  padding: 3% 0;
  padding: 18px 0;
  width: 40%;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 700;
  margin: 4%;
  border: none;
  transition: 0.5s;
}

.discoverCloseIcon {
  position: absolute;
  top: 10px;
  right: -10%;
  z-index: 9999;
  font-size: 40px !important;
  cursor: pointer;
  color: gray;
}

/* subFooter css */
.discoverSubFooterMainDiv {
  width: 100%;
  padding: 5% 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3%;
}

.discoverSubFooterTextDiv {
  width: 49%;
}

.discoverSubFooterTextDiv h1 {
  color: #0e3b7d;
  padding: 3% 0;
  font-size: 40px;
}

.discoverSubFooterImgDiv {
  width: 49%;
}

.discoverFooterTextDivMainRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 2% 0;
  gap: 20px;
}

.discoverFooterTextDivMainRow h3 {
  color: black;
  font-weight: 400;
  font-size: 25px;
}

@media (max-width: 1600px) {
  .discoverTextDiv h1 {
    font-size: 38px;
  }

  .discoverSubFooterTextDiv h1 {
    font-size: 30px;
  }

  .discoverFooterTextDivMainRow h3 {
    font-size: 22px;
  }

  .discoverFormModal {
    width: 60%;
  }

  .discoverFromDiv h1 {
    font-size: 26px;
  }
  .discoverFormInputDiv {
    padding: 0% 0;
    margin: 2% 0;
  }
}

@media (max-width: 1024px) {
  .discoverFormModal {
    width: 80%;
  }

  .discoverSubFooterMainDiv {
    flex-direction: column;
  }

  .discoverSubFooterTextDiv {
    width: 100%;
  }

  .discoverSubFooterImgDiv {
    width: 100%;
    margin: 5% 0;
  }
}

@media (max-width: 768px) {
  .discoverFromDiv button {
    font-size: 14px;
  }

  .discoverTextDiv h1 {
    font-size: 28px;
  }

  .discoverFormModal {
    width: 90%;
    border: solid 5px #0e3b7d;
  }

  .discoverFromDiv {
    width: 90%;
  }

  .discoverCloseIcon {
    right: -4%;
  }
}

@media (max-width: 600px) {
  .discoverFromDiv button {
    width: 50%;
  }

  .discoverCloseIcon {
    font-size: 26px !important;
    right: -2%;
  }
}

@media (max-width: 500px) {
  .discoverFormInputDiv input {
    margin: 2% 0;
    padding: 1%;
    border-radius: 12px;
    font-size: 14px;
  }

  .discoverFromDiv p {
    font-size: 12px;
  }

  .discoverFromDiv h1 {
    font-size: 16px;
    padding-bottom: 0;
  }

  .discoverTextDiv h1 {
    font-size: 16px;
    line-height: 30px;
  }

  .discoverTextDiv h3 {
    margin: 3% 0;
    font-size: 14px !important;
  }

  .discoverTextDiv h2 {
    font-size: 14px;
  }

  .discoverSubFooterTextDiv h1 {
    font-size: 14px;
  }

  .discoverFooterTextDivMainRow h3 {
    font-size: 15px;
    line-height: 30px;
  }

  .discoverFooterTextDivMainRow {
    align-items: flex-start;
    margin: 4% 0;
  }

  .discoverVideoButton {
    width: 70px;
  }

  .discoverFormInputDiv label {
    font-size: 15px;
  }

  .discoverFromDiv button {
    width: 61%;
  }
}

@media (max-width: 400px) {
  .discoverFromDiv button {
    width: 65%;
    font-weight: 500;
  }

  .discoverFromDiv h1 {
    font-size: 15px;
  }

  .discoverTextDiv h1 {
    font-size: 14px;
    line-height: 20px;
    margin: 4% 0;
  }

  .discoverTextDiv h2 {
    font-size: 12px;
  }

  .discoverFooterTextDivMainRow h3 {
    font-size: 15px;
    line-height: 20px;
  }
}
