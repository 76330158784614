.LatestProjectDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin-top: 2%;
  padding-bottom: 4%;
  /* overflow-x: hidden; */
  /* height: 100vh; */
}
.LatestProjectsSlider {
  position: relative;
  margin: 2% 0;
  padding: 0 5% !important;
  width: 100%;
  display: flex;
  justify-content: center;
  /* overflow: hidden !important; */
  /* overflow-x: hidden; */
}
.SliderDiv {
  width: 90% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0 1%;
  /* overflow-x: scroll; */
}

.SliderDiv::-webkit-scrollbar {
  display: none;
}
.styles-module_item-container__a8zaY {
  width: 100%;
}
.SliderImgs {
  width: 100%;
  object-fit: cover;
  height: 100%;
  transform: scale(1);
  transition: 0.5s;
  z-index: 99;
  /* border-radius: 15px !important; */
}
/* .SliderImgs:hover{
    background-color: black;
    z-index: -1;
} */
.ProjectsCompletedDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 22px;
  background: url(../../assets/images/contact_section_bg.png),
    linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%), #003b7e;
  background-blend-mode: multiply, normal, normal;
  background-size: cover;

  width: 90%;
  padding: 4% 15%;
  margin: 5%;
}
.SliderProject {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  position: relative;
  height: 500px;
  cursor: grab;
  overflow: hidden;
}
.HoverDetail {
  color: white !important;
  background: linear-gradient(0deg, #003b7e 0%, rgba(0, 59, 126, 0) 100%);
  opacity: 0;
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: left;
  justify-content: flex-end;
  position: absolute;
  transition: 0.5s;
  padding: 0 0 0 5%;
  z-index: 999;
}
.HoverDetailSubDiv {
  width: 100%;
  height: 45%;
  opacity: 0;
  display: flex;
  padding-left: 350px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  transition: 0.5s;
}
.HoverDetailLite {
  color: black !important;
  background-color: white !important;
  /* border-radius: 12px; */
  opacity: 0;
  width: 96% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  position: absolute;
  transition: 0.5s;
}
.HoverDetail:hover {
  opacity: 1;
}
.HoverDetail:hover .HoverDetailSubDiv {
  opacity: 1;
  padding-left: 0;
}
.HoverDetail:hover .HoverDivTypo {
  opacity: 0.8;
}
.HoverDetail:hover + .SliderImgs {
  transform: scale(1.1) !important;
}
.HoverDetailLite:hover {
  opacity: 0.9;
}
.hoverDivLogoDiv {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}
.hoverDivLogo {
  padding: 0 0 5% 0;
  width: 100%;
  /* height: 100%; */
}
.HoverDivTypo {
  /* color: white; */
  width: 80%;
  font-size: 1rem !important;
  font-weight: 400;
  opacity: 0;
  transition: 1s;
  /* white-space: nowrap; */
}
.HoverDivBtn {
  width: 100%;
  margin-bottom: 5%;
  border: none;
}
.HoverDivBtnDark {
  width: 100%;
  margin-bottom: 5%;
  margin-left: 0;
  /* padding:5% 3%; */
  border: none;
}
.HoverDivBtnSubdiv {
  width: 100%;
  display: flex;
  align-items: center;
}

.HoverDivBtnLink {
  font-size: 0.9rem !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  font-family: Raleway !important;
  text-transform: capitalize !important;
  padding: 2% 3% 2% 0 !important;
}

.Awesome_Slider {
  height: 40vh;
}
.awssld {
  --content-background-color: white !important;
  --organic-arrow-color: rgb(75, 173, 223) !important;
}
.awssld__bullets {
  visibility: hidden !important;
}
.ProjectsCompleted_Timer {
  position: absolute;
  color: #f8f8f890;
  width: 30%;
  top: -20%;
  left: 15%;
}
.ProjectsCompleted_MedKit {
  position: absolute;
  color: #f8f8f890;
  top: -20%;
  left: 7%;
}
.ProjectsCompleted_Award {
  position: absolute;
  color: #f8f8f890;
  width: 32%;
  top: -30%;
  left: 10%;
}
.ProjectsCompleted_Num {
  font-family: Montserrat !important;
  color: #f8f8f8 !important;
  font-size: 3rem !important;
  font-weight: 800 !important;
  text-align: center;
}
.ProjectsCompleted_H {
  color: #f8f8f8;
  font-family: Montserrat !important;
  font-size: 1.7rem !important;
  font-weight: 400 !important;
  text-align: center;
}
.custom-dot-list-style {
  margin-bottom: -3%;
}

.react-multi-carousel-dot button {
  display: inline-block;
  width: 12px;
  height: 5px;
  border-radius: 50px;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  background: #bebebe69;
  transition: background 0.5s;
  border: none;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
}
.react-multi-carousel-dot--active button {
  background: #42c2ff;
  width: 50px;
  border-radius: 10px;
}
.HoverDivBtnArrow {
  color: #f8f8f8;
  width: 6%;
}
.react-multiple-carousel__arrow--right {
  right: 2% !important;
}
.react-multiple-carousel__arrow--left {
  left: 4% !important;
}

@media only screen and (max-width: 1800px) {
  .SliderProject {
    /* width: 290px; */
    height: 400px;
  }
  .HoverDetailSubDiv {
    height: 55%;
  }
  .SliderDiv {
    padding: 0 3%;
  }
}
@media only screen and (max-width: 1680px) {
  .SliderDiv {
    padding: 0 2%;
  }
  .HoverDetailSubDiv {
    height: 60%;
  }
}
@media only screen and (max-width: 1366px) {
  .ProjectsCompleted_Num {
    font-size: 2.5rem !important;
  }
  .ProjectsCompleted_H {
    font-size: 1.4rem !important;
  }
  .ProjectsCompleted_Timer {
    top: -30%;
  }
  .ProjectsCompleted_MedKit {
    width: 30%;
  }
  .ProjectsCompleted_Award {
    width: 35%;
    top: -30%;
    left: 7%;
  }
  .SliderProject {
    /* width: 250px; */
    height: 370px;
  }
  .HoverDetailSubDiv {
    height: 65%;
  }
  .HoverDivTypo {
    width: 95%;
    font-size: 0.9rem !important;
  }
}
@media only screen and (max-width: 1200px) {
  .hoverDivLogo {
    width: 60%;
  }

  .HoverDivBtn {
    font-size: 1rem;
  }
  .HoverDivBtnDark img {
    width: 15%;
  }
  .ProjectsCompleted_Num {
    font-size: 2rem !important;
  }
  .ProjectsCompleted_H {
    font-size: 1rem !important;
  }
  .ProjectsCompleted_Timer {
    top: -40%;
  }
  .ProjectsCompleted_MedKit {
    width: 30%;
    top: -25%;
    left: 5%;
  }
  .ProjectsCompleted_Award {
    /* width: 25%; */
    top: -45%;
    left: 2%;
  }
  .SliderProject {
    /* width: 220px; */
    height: 340px;
  }
  .HoverDetailSubDiv {
    height: 65%;
  }
  .HoverDivTypo {
    width: 100%;
  }
  .SliderDiv {
    padding: 0 1%;
  }
}
@media only screen and (max-width: 1024px) {
  .SliderDiv {
    padding: 0 3%;
  }
}
@media only screen and (max-width: 900px) {
  .ProjectsCompleted_Num {
    font-size: 1.2rem !important;
  }
  .ProjectsCompleted_H {
    font-size: 0.7rem !important;
  }

  .ProjectsCompleted_Timer {
    top: -80%;
  }
  .ProjectsCompleted_MedKit {
    top: -60%;
    left: 7%;
  }
  .ProjectsCompleted_Award {
    top: -80%;
  }

  .SliderDiv {
    padding: 0 2%;
  }
  .HoverDivBtnArrow {
    width: 10%;
  }
  /* .HoverDetail {
    opacity: 1;
  }
  .HoverDetailSubDiv {
    opacity: 1;
    padding-left: 0;
  }
  .HoverDetailLite {
    opacity: 0.9;
  }
  .HoverDivTypo {
    opacity: 0.8;
  } */
}

@media only screen and (max-width: 768px) {
  .HoverDetailSubDiv {
    height: 75%;
  }
  .SliderProject {
    /* width: 175px; */
    height: 300px;
  }
}
@media only screen and (max-width: 600px) {
  .SliderProject {
    /* width: 150px; */
    height: 250px;
  }
  .HoverDetailSubDiv {
    height: 95%;
  }
  .HoverDivBtnLink {
    font-size: 0.7rem !important;
  }
  .SliderDiv {
    padding: 1%;
  }
  .ProjectsCompleted_Num {
    font-size: 1.3rem !important;
  }
  .ProjectsCompleted_H {
    font-size: 0.9rem !important;
  }

  /* .ProjectsCompletedDiv{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #003B7E;
        width: 90%;
        padding: 4% 15%;
        border-radius: 15px;
        margin: 5%;
    } */
  .ProjectsCompletedDiv {
    flex-direction: column;
    width: 40%;
    padding: 7% 0;
    /* height: 40vh; */
  }
  .About_InfoLeft {
    margin-top: 8%;
  }
  .About_InfoCenter {
    margin: 16% 0;
  }
  .About_InfoRight {
    margin-bottom: 8%;
  }
  .ProjectsCompleted_Timer {
    width: 30%;
    top: -80%;
    left: 12%;
  }
  .ProjectsCompleted_MedKit {
    width: 35%;
    top: -60%;
    left: 5%;
  }
  .ProjectsCompleted_Award {
    width: 30%;
    top: -90%;
    left: 10%;
  }
  .HoverDivBtnDark {
    width: 70% !important;
  }
  /* .ProjectsCompleted_Num{
    font-size: 1.5rem !important;
}
.ProjectsCompleted_H{
    font-size: .7rem !important;
    width: 100%;
} */
}
@media only screen and (max-width: 520px) {
  .HoverDetailSubDiv {
    height: 65%;
  }
  .SliderProject {
    /* width: 250px; */
    height: 350px;
  }
  .HoverDivBtnLink {
    font-size: 0.9rem !important;
  }
  .HoverDivTypo {
    width: 90%;
  }
}
@media only screen and (max-width: 480px) {
  .SliderProject {
    /* width: 200px; */
    height: 300px;
  }

  .About_InfoLeft {
    margin-top: 10%;
  }
  .About_InfoCenter {
    margin: 20% 0;
  }
  .About_InfoRight {
    margin-bottom: 10%;
  }
  .ProjectsCompleted_Num {
    font-size: 1.3rem !important;
  }
  .ProjectsCompleted_H {
    font-size: 0.8rem !important;
  }
  .ProjectsCompletedDiv {
    width: 60%;
    margin: 7% 0;
  }
}
@media only screen and (max-width: 400px) {
  .custom-dot-list-style {
    margin-bottom: -5%;
  }
  .SliderProject {
    /* width: 180px; */
    height: 270px;
  }
  .ProjectsCompletedDiv {
    width: 70%;
    margin: 10% 0 5% 0;
  }
}
@media only screen and (max-width: 330px) {
  .SliderProject {
    /* width: 150px; */
    height: 240px;
  }
  .ProjectsCompletedDiv {
    height: 55vh;
  }
}
