.ServiceCard_MainDiv {
  width: 100%;
  height: 250px;
  padding: 5% 8%;
  box-shadow: 0px 3.23497px 12.1311px 4.04372px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  margin: 5% 0;
  transition: 0.5s;
  transform: scale(1);
  overflow: hidden;
}

.ServiceCard_Link {
  color: #000;
}
.ServiceCard_ImageDiv {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ServiceCard_Image {
  width: 100%;
  color: #000;
  transition: 0.5s;
}
.ServiceCard_Image_MD {
  width: 100%;
  color: #003b7e;
  transition: 0.5s;
}

.ServiceCard_Divider {
  width: 20%;
  height: 4px;
  background: #00a4ef;
  margin: 5% 0 !important;
  border-radius: 10px;
  transition: 0.5s;
}
.ServiceCard_heading {
  margin: 3% 0;
  text-transform: uppercase;
  transition: 0.5s;
  font-size: 1rem;
}
.ServiceCard_desp {
  margin: 7% 0;
  transition: 0.5s;
  color: #404040b3;
  font-size: 0.9rem;
}
.ServiceCard_MainDiv:hover {
  transform: scale(1.05) !important;
}

.ServiceCard_MainDiv:hover .ServiceCard_Image {
  color: #fff !important;
}
.ServiceCard_MainDiv:hover .ServiceCard_Image_MD {
  color: #fff !important;
}
.ServiceCard_MainDiv:hover .ServiceCard_Divider {
  background: #fff !important;
}
.ServiceCard_MainDiv:hover .ServiceCard_heading {
  color: #fff !important;
}
.ServiceCard_MainDiv:hover .ServiceCard_desp {
  color: #fff !important;
  opacity: 0.7;
}
.ServiceCard_MainDiv:hover .ServiceCard_BtnImg {
  color: #fff !important;
}
.ServiceCard_MainDiv:hover .ServiceCard_BtnText {
  color: #fff !important;
}

/* Service we provide section */

.ServiceCardSection_MainDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ServiceCardSection_ContainerGrid {
  display: flex;
  justify-content: space-between;
  padding: 3% 10%;
  width: 80% !important;
}
.ServiceCardSection_SectionHeaderDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 3%;
}
.ServiceCardSection_Divider {
  height: 5px;
  width: 10%;
  background: #00a4ef;
  border-radius: 10px;
}
.ServiceCardSection_H {
  margin: 1% 0;
  color: #003b7e;
}
.ServiceCardSection_P {
  color: rgba(64, 64, 64, 0.9);
}
.ServiceCard_BtnDiv {
  margin-top: 2%;
  display: flex;
  align-items: center;
  padding: 1% 0;
}
.ServiceCard_BtnText {
  margin-right: 2%;
  color: #003b7e;
  transition: 0.5s;
}
.ServiceCard_BtnImg {
  width: 6%;
  transition: 0.5s;

  /* color: #003b7e; */
}
/* flip */
.flip-card {
  width: 100%;
  height: 280px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
}
.flip-card:hover .flip-card-inner {
  transform: rotateX(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  /* background-color: #bbb; */
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateX(180deg);
}

@media only screen and (max-width: 1366px) {
  .ServiceCard_heading {
    font-size: 1.1rem;
  }
  .ServiceCardSection_ContainerGrid {
    padding: 3% 5%;
  }
}
@media only screen and (max-width: 900px) {
  .ServiceCardSection_ContainerGrid {
    padding: 3% 10%;
  }
  .ServiceCard_MainDiv {
    padding: 5% 5% 2% 5%;
    height: 100%;
  }
  .ServiceCardSection_Grid {
    margin: 2% 0 !important;
  }
}
@media only screen and (max-width: 600px) {
  .ServiceCardSection_H {
    font-size: 1.5rem;
  }
  .ServiceCardSection_P {
    font-size: 1rem;
  }
  .ServiceCard_heading {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .ServiceCardSection_H {
    font-size: 1.3rem;
  }

  .ServiceCardSection_Grid {
    margin: 3% 0 !important;
  }
  .ServiceCardSection_ContainerGrid {
    padding: 3% 5%;
  }
}
