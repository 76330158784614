/* Importing all CSS files */
@import url('./Common/Header.css');
@import url('./Common/NavBar.css');
@import url('./Common/ServiceProvided.css');
@import url('./Common/ServiceWeProvideCard.css');
@import url('./Common/LatestProject.css');
@import url('./Common/SectionHeader.css');
@import url('./Common/AboutSection.css');
@import url('./Common/AboutSectionV2.css');
@import url('./Common/ClientSection.css');
@import url('./Common/ContactUsSection.css');
@import url('./Common/ContactSection.css');
@import url('./Common/Footer.css');
@import url('./Common/BlogSection.css');
@import url('./Common/MoreProjectCards.css');
@import url('./Common/ReviewCard.css');
@import url('./Common/AboutCompany.css');
@import url('./Common/VisionSection.css');
@import url('./Common/ModernAgency.css');
@import url('./Service/ServiceFrame.css');
@import url('./Service/KeyFeature.css');
@import url('./Common/BlogHeader.css');
@import url('./Common/BlogReadArticles.css');
@import url('./Common/BlogList.css');
@import url('./Common/OurProjectTabs.css');
@import url('./Common/OurProjectCard.css');
@import url('./Common/ProjectHeader.css');
@import url('./Common/ProjectCard.css');
@import url('./Common/BasicModal.css');
@import url('./Common/OurDevelopers.css');
@import url('./Common/DeveloperWorkProcess.css');
@import url('./Common/LocationSection.css');
@import url('./Common/LegalMention.css');
@import url('./Common/newmenu.css');
@import url('./Clients/Clients.css');
@import url('./Projects/DetailHeader.css');
@import url('./Projects/DetailOverview.css');
@import url('./Projects/DetailDemo.css');
@import url('./Projects/DetailService.css');
@import url('./Projects/DetailDesign.css');
@import url('./Projects/DetailFeedback.css');
@import url('./Projects/DetailTheme.css');
@import url('./Projects/DetailLogo.css');
@import url('./Projects/DetailBookDemo.css');
@import url('./Projects/DetailAppDev.css');
@import url('./Projects/DetailWebDev.css');
@import url('./Blog/BlogReadMore.css');
@import url('./Common/SocialBanner.css');
@import url('./Common/Hero.css');
@import url('./Common/BlogSectionV2.css');
@import url('./Common/ErrorSection.css');
@import url('./AboutV2/AboutHeaderV2.css');
@import url('./AboutV2/AboutBanner.css');
@import url('./AboutV2/ModernAgencyV2.css');
@import url('./AboutV2/VisionSectionV2.css');
@import url('./AboutV2/AboutTeamV2.css');
@import url('./adPage/AdContact.css');
@import url('./adPage/Certification.css');
@import url('./adPage/AvailableProfiles.css');
@import url('./adPage/ProfileCard.css');
@import url('./adPage/TestimonialV2.css');
@import url('./adPage/ReviewCardV2.css');
@import url('./Discover_Uspm/DiscoverUspm.css');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Raleway, Montserrat !important;
  /* font-family: 'Montserrat', 'Raleway', sans-serif; */
  font-display: swap;
  /* overflow: hidden !important; */
}

a {
  text-decoration: none !important;
}

.progress-bar {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  height: 10px !important;
  /* background: red  !important; */
  transform-origin: 0% !important;
  z-index: 99999999 !important;
}

.HomeContainer,
.ContactContainer,
.ProjectsContainer,
.BlogContainer,
.AboutContainer,
.Services_WebContainer,
.Services_SoftwareContainer,
.Services_UiContainer,
.Services_DemandContainer,
.Services_AppContainer,
.Permispop_Container {
  overflow-x: hidden !important;
}

.newProjectsOverViewImg {
  margin-top: 80px;
  width: 95%;
}

/* Primary Variables */
:root {
  --primary: 'green';
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #24548b inset !important;
  -webkit-text-fill-color: #ffffff;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

/* 
body {
  overflow: overlay;
}

::-webkit-scrollbar {
  width: 1rem;
}

::-webkit-scrollbar-thumb {
  background: #003b7e;
}

::-webkit-scrollbar-track {
  background: transparent;
} */

section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  scroll-snap-align: center;
  perspective: 500px;
}

section > div {
  width: 300px;
  height: 400px;
  position: relative;
  max-height: 90vh;
  margin: 20px;
  background: var(--white);
  overflow: hidden;
}

.gridsWork {
  width: 95%;
  height: 400px;
  margin-left: 5%;
  display: flex;
  overflow-x: scroll !important;
  overflow-y: hidden;
}

.gridsWorkCard {
  width: 50%;
  margin-right: 1%;
  height: 100%;
}

.gridsWorkCard img {
  /* object-fit: cover; */
  /* height: 100%; */
}

.fallbackScreen {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #003b7e;
}

.fallbackScreen img {
  width: 20%;
}
